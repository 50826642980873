<template>
  <div class="box-merged">
    <a-row type="flex" align="middle" justify="start" class="mb-2 mt-3">
      <a-col :span="props.type == 'cancellation' ? 24 : 11" v-if="props.type != 'new'">
        <div class="box-left box-bordered p-4">
          <a-row
            align="middle"
            justify="end"
            class="mb-3"
            v-if="props.show_communication && emailsFrom.length > 0"
          >
            <span>Correo asociado a reservas:</span>
            <span class="mx-2 bordered">{{ emailsFrom[0] }}</span>
            <span class="cursor-pointer" v-on:click="showModalEmails(emailsFrom)">
              <i class="bi bi-plus-circle-dotted text-danger"></i>
            </span>
          </a-row>
          <div class="box-title">
            <a-row type="flex" align="middle" justify="space-between">
              <a-col class="d-flex">
                <span>
                  <i class="bi bi-building-fill"></i>
                </span>
                <span class="mx-2">
                  <b>{{ from.name }}</b>
                </span>
                <a-tag color="#c63838" class="mx-2">
                  {{ from.code }}
                </a-tag>
              </a-col>
            </a-row>
          </div>

          <div class="bg-pink-stick px-3 py-2 my-3">
            <a-row type="flex" justify="space-between" align="middle">
              <a-col>
                <CalendarOutlined />
                <b>{{ formatDate(filesStore.getFileItinerary.date_in, 'DD/MM/YYYY') }}</b>
              </a-col>
              <a-col>
                <b>Pasajeros: </b>
                {{ filesStore.getFileItinerary.adults }} <i class="bi bi-person-fill"></i>
                {{ filesStore.getFileItinerary.children }} <i class="bi bi-person"></i>
              </a-col>
              <a-col>
                <a href="javascript:;" class="text-danger text-underline"
                  >Más información del servicio</a
                >
              </a-col>
              <a-col>
                <i class="bi bi-warning-triangle text-warning"></i>
                <b>Costo:</b>
                <b class="h5 text-danger mb-0 mx-1"
                  >$ {{ filesStore.getFileItinerary.total_amount }}</b
                >
              </a-col>
            </a-row>
          </div>

          <template v-if="filesStore.getFileItinerary.send_comunication">
            <a-row align="middle" type="flex" justify="space-between" class="mx-2">
              <a-col>
                <div
                  v-bind:class="[
                    'd-flex cursor-pointer',
                    !showNotesFrom ? 'text-dark-gray' : 'text-danger',
                  ]"
                  @click="showNotesFrom = !showNotesFrom"
                >
                  <template v-if="showNotesFrom">
                    <i
                      class="bi bi-check-square-fill text-danger d-flex"
                      style="font-size: 1.5rem"
                    ></i>
                  </template>
                  <template v-else>
                    <i class="bi bi-square d-flex" style="font-size: 1.5rem"></i>
                  </template>
                  <span class="mx-2">Agregar nota al proveedor</span>
                </div>
              </a-col>
            </a-row>
            <div class="mb-3 mx-2" v-if="showNotesFrom">
              <template v-if="lockedNotesFrom">
                <a-card style="width: 100%" class="mt-3" :headStyle="{ background: black }">
                  <template #title> Nota en reserva para el proveedor:</template>
                  <template #extra>
                    <a href="javascript:;" @click="lockedNotesFrom = false" class="text-danger">
                      <i class="bi bi-pencil"></i>
                    </a>
                  </template>
                  <p class="mb-2">
                    <b>{{ notesFrom }}</b>
                  </p>
                  <template v-for="(file, f) in filesFrom" :key="f">
                    <a-row align="middle" class="mb-2">
                      <i class="bi bi-paperclip"></i>
                      <a :href="file" target="_blank" class="text-dark mx-1">
                        {{ showName(file) }}
                      </a>
                    </a-row>
                  </template>
                </a-card>
              </template>

              <template v-if="!lockedNotesFrom">
                <p class="text-danger my-2">Nota para el proveedor:</p>
                <a-row align="top" justify="space-between">
                  <a-col flex="auto">
                    <a-textarea
                      v-model:value="notesFrom"
                      placeholder="Escribe una nota para el proveedor que podrás visualizar en la comunicación"
                      :auto-size="{ minRows: 2 }"
                    />
                  </a-col>
                  <a-col class="mx-2">
                    <file-upload
                      v-bind:folder="'communications'"
                      @onResponseFiles="responseFilesFrom"
                    />
                  </a-col>
                  <a-col>
                    <a-button
                      danger
                      type="default"
                      size="large"
                      v-bind:disabled="!(notesFrom != '' || filesFrom.length > 0)"
                      class="d-flex ant-row-middle text-600"
                      @click="lockedNotesFrom = true"
                      :loading="communicationsStore.isLoading"
                    >
                      <i class="bi bi-floppy"></i>
                    </a-button>
                  </a-col>
                </a-row>
              </template>
            </div>
            <template v-if="lockedNotesFrom || !showNotesFrom">
              <a-row align="middle" type="flex" justify="end" class="mx-2">
                <a-col class="ant-row-end">
                  <a-button
                    danger
                    type="default"
                    class="d-flex ant-row-middle text-600"
                    @click="showCommunicationFrom()"
                    :loading="communicationsStore.isLoading || communicationsStore.isLoadingAsync"
                  >
                    <i
                      class="bi bi-search"
                      v-if="!(communicationsStore.isLoading || communicationsStore.isLoadingAsync)"
                    ></i>
                    <span class="mx-2">Ver comunicación</span>
                  </a-button>
                </a-col>
              </a-row>
            </template>
          </template>
        </div>
      </a-col>
      <template v-if="props.to">
        <a-col
          :span="2"
          class="text-center merge-icon"
          v-if="props.type != 'new' && props.type != 'cancellation'"
        >
          <i class="bi bi-arrow-right-circle" style="font-size: 4rem"></i>
        </a-col>
        <a-col :span="props.type != 'modification' ? 24 : 11">
          <div class="box-left box-bordered p-4" v-for="(_service, s) in to" :key="s">
            <a-row align="middle" justify="end" class="mb-3" v-if="emailsTo.length > 0">
              <span>Correo asociado a reservas:</span>
              <span class="mx-2 bordered">{{ emailsTo[0] }}</span>
              <span class="cursor-pointer" v-on:click="showModalEmails(emailsTo)">
                <i class="bi bi-plus-circle-dotted text-danger"></i>
              </span>
            </a-row>
            <div class="box-title">
              <a-row type="flex" align="middle" justify="space-between">
                <a-col class="d-flex">
                  <span>
                    <i class="bi bi-building-fill"></i>
                  </span>
                  <span class="mx-2">
                    <b>{{ _service.name }}</b>
                  </span>
                  <a-tag color="#c63838" class="mx-2">
                    {{ _service.code }}
                  </a-tag>
                </a-col>
              </a-row>
            </div>

            <div class="bg-purple-stick px-3 py-2 my-3">
              <a-row type="flex" justify="space-between" align="middle">
                <a-col>
                  <CalendarOutlined />
                  <b>{{ formatDate(_service.search_parameters_services.date, 'DD/MM/YYYY') }}</b>
                </a-col>
                <a-col>
                  <b>Pasajeros: </b>
                  {{ filesStore.getDefaultAdults }} <i class="bi bi-person-fill"></i>
                  {{ filesStore.getDefaultChildren }} <i class="bi bi-person"></i>
                </a-col>
                <a-col>
                  <a href="javascript:;" class="text-danger text-underline"
                    >Más información del servicio</a
                  >
                </a-col>
                <a-col>
                  <i class="bi bi-warning-triangle text-warning"></i>
                  <b>Costo:</b>
                  <b class="h5 text-danger mb-0 mx-1">$ {{ _service.price }}</b>
                </a-col>
              </a-row>
            </div>

            <template v-if="filesStore.getFileItinerary.send_comunication">
              <a-row align="middle" type="flex" justify="space-between" class="mx-2">
                <a-col>
                  <div
                    v-bind:class="[
                      'd-flex cursor-pointer',
                      !showNotesTo ? 'text-dark-gray' : 'text-danger',
                    ]"
                    @click="showNotesTo = !showNotesTo"
                  >
                    <template v-if="showNotesTo">
                      <i
                        class="bi bi-check-square-fill text-danger d-flex"
                        style="font-size: 1.5rem"
                      ></i>
                    </template>
                    <template v-else>
                      <i class="bi bi-square d-flex" style="font-size: 1.5rem"></i>
                    </template>
                    <span class="mx-2">Agregar nota al proveedor</span>
                  </div>
                </a-col>
              </a-row>
              <div class="mb-3 mx-2" v-if="showNotesTo">
                <template v-if="lockedNotesTo">
                  <a-card style="width: 100%" class="mt-3" :headStyle="{ background: black }">
                    <template #title> Nota en reserva para el proveedor:</template>
                    <template #extra>
                      <a href="javascript:;" @click="lockedNotesTo = false" class="text-danger">
                        <i class="bi bi-pencil"></i>
                      </a>
                    </template>
                    <p class="mb-2">
                      <b>{{ notesTo }}</b>
                    </p>
                    <template v-for="(file, f) in filesTo" :key="f">
                      <a-row align="middle" class="mb-2">
                        <i class="bi bi-paperclip"></i>
                        <a :href="file" target="_blank" class="text-dark mx-1">
                          {{ showName(file) }}
                        </a>
                      </a-row>
                    </template>
                  </a-card>
                </template>

                <template v-if="!lockedNotesTo">
                  <p class="text-danger my-2">Nota para el proveedor:</p>
                  <a-row align="top" justify="space-between">
                    <a-col flex="auto">
                      <a-textarea
                        v-model:value="notesTo"
                        placeholder="Escribe una nota para el proveedor que podrás visualizar en la comunicación"
                        :auto-size="{ minRows: 2 }"
                      />
                    </a-col>
                    <a-col class="mx-2">
                      <file-upload
                        v-bind:folder="'communications'"
                        @onResponseFiles="responseFilesTo"
                      />
                    </a-col>
                    <a-col>
                      <a-button
                        danger
                        type="default"
                        size="large"
                        v-bind:disabled="!(notesTo != '' || filesTo.length > 0)"
                        class="d-flex ant-row-middle text-600"
                        @click="lockedNotesTo = true"
                        :loading="communicationsStore.isLoading"
                      >
                        <i class="bi bi-floppy"></i>
                      </a-button>
                    </a-col>
                  </a-row>
                </template>
              </div>
              <template v-if="lockedNotesTo || !showNotesTo">
                <a-row align="middle" type="flex" justify="end" class="mx-2">
                  <a-col class="ant-row-end">
                    <a-button
                      danger
                      type="default"
                      class="d-flex ant-row-middle text-600"
                      @click="showCommunicationTo(s)"
                      :loading="communicationsStore.isLoading || communicationsStore.isLoadingAsync"
                    >
                      <i
                        class="bi bi-search"
                        v-if="
                          !(communicationsStore.isLoading || communicationsStore.isLoadingAsync)
                        "
                      ></i>
                      <span class="mx-2">Ver comunicación</span>
                    </a-button>
                  </a-col>
                </a-row>
              </template>
            </template>
          </div>
        </a-col>
      </template>
    </a-row>
  </div>

  <div class="my-3" v-if="props.buttons">
    <a-row type="flex" justify="end" align="middle">
      <a-col>
        <a-button
          type="default"
          class="btn-default mx-2 px-4 text-600"
          v-on:click="prevStep()"
          default
          :disabled="filesStore.isLoading"
          size="large"
        >
          Cancelar
        </a-button>
        <a-button
          type="primary"
          class="mx-2 px-4 text-600"
          v-on:click="proccessReservation()"
          default
          :disabled="filesStore.isLoading || filesStore.isLoadingAsync"
          size="large"
        >
          <template v-if="type == 'cancellation'">Continuar</template>
          <template v-else>Reservar</template>
        </a-button>
      </a-col>
    </a-row>
  </div>

  <a-modal
    v-model:open="modal.open"
    :title="modal.subject"
    :width="720"
    :closable="true"
    :maskClosable="false"
  >
    <template #footer>
      <a-row align="middle" justify="center">
        <a-col>
          <a-button
            key="button"
            type="primary"
            default
            size="large"
            class="text-600"
            @click="closeModal"
            >Cerrar
          </a-button>
        </a-col>
      </a-row>
    </template>
    <div v-html="modal.html"></div>
  </a-modal>
</template>

<script setup>
  import { onBeforeMount, ref } from 'vue';
  import { formatDate } from '@/utils/files.js';
  import { CalendarOutlined } from '@ant-design/icons-vue';
  import { useFilesStore } from '@store/files';
  import { useCommunicationsStore } from '@/stores/global';
  import FileUpload from '@/components/global/FileUploadComponent.vue';
  import Cookies from 'js-cookie';

  const emit = defineEmits(['onPrevStep', 'onNextStep', 'onLoadReservation']);

  const params_cancellation = ref({});

  const props = defineProps({
    buttons: {
      type: Boolean,
      default: () => true,
    },
    show_communication: {
      type: Boolean,
      default: () => true,
    },
    title: {
      type: Boolean,
      default: () => true,
    },
    token_search_hotels: {
      type: String,
      default: () => '',
    },
    from: {
      type: Object,
      default: () => {},
    },
    to: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Array,
      default: () => [],
    },
    date_from: {
      type: String,
      default: () => '',
    },
    date_to: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => '',
    },
  });

  onBeforeMount(async () => {
    client_id.value = localStorage.getItem('client_id');

    if (client_id.value == '' || client_id.value == null) {
      client_id.value = 15766;
    }

    if (props.type == 'new') {
      type_to.value = 'new';
    }

    if (props.type == 'modification') {
      if (props.from.object_code != props.to[0].code) {
        // Es son hoteles diferentes.. update
        type_from.value = 'cancellation';
        type_to.value = 'new';
      } else {
        type_from.value = 'modification';
        type_to.value = '';
      }
    }

    if (props.type == 'cancellation') {
      type_from.value = 'cancellation';
      type_to.value = '';
    }

    // Generar el param query para la cancelación..
    let services_cancellation = [];

    if (props.from != undefined) {
      props.from.services.forEach((service) => {
        let compositions = [];

        service.compositions.forEach((composition) => {
          compositions.push(composition.id);
        });

        let item = {
          id: service.id,
          compositions: compositions,
        };

        services_cancellation.push(item);
      });
    }

    if (props.from != undefined && props.from.object_code != undefined) {
      await searchProvidersFrom(props.from.object_code);
    }

    params_cancellation.value = services_cancellation;

    if (props.to != undefined) {
      props.to.forEach(async (_provider) => {
        await searchProvidersTo(_provider.code);
      });
    }
  });

  const filesStore = useFilesStore();
  const communicationsStore = useCommunicationsStore();

  // const filter = ref({});
  const type_from = ref('');
  const type_to = ref('');

  const flagModal = ref(false);
  const emailsFrom = ref([]);
  const emailsTo = ref([]);

  const showNotesFrom = ref(false);
  const showNotesTo = ref(false);

  const lockedNotesFrom = ref(false);
  const lockedNotesTo = ref(false);

  const notesFrom = ref('');
  const filesFrom = ref([]);

  const notesTo = ref('');
  const filesTo = ref([]);
  const client_id = ref('');

  const modal = ref({
    open: false,
    html: '',
    subject: '',
  });

  const showCommunicationFrom = async () => {
    console.log('FROM: ', props.from);

    let params = {
      services: params_cancellation.value,
      notas: notesFrom.value,
      attachments: filesFrom.value,
    };

    await communicationsStore.previewCommunication(
      'itineraries/' + props.from.id,
      params,
      'service',
      type_to.value
    );

    modal.value.html = communicationsStore.getHtml;
    modal.value.subject = communicationsStore.getSubject;
    modal.value.open = true;
  };

  const showCommunicationTo = async (_index) => {
    console.log('TO: ', props.to);
    let equivalence = props.to[_index];
    console.log(equivalence);

    /*
    await communicationsStore.previewCommunication(
      'itineraries/' + props.from.id,
      params,
      'service',
      type_to.value
    );

    modal.value.html = communicationsStore.getHtml;
    modal.value.subject = communicationsStore.getSubject;
    modal.value.open = true;
    */
  };

  const searchProvidersFrom = async (_object_code) => {
    await filesStore.fetchProviders(_object_code);
    emailsFrom.value =
      typeof filesStore.getProvider.emails != 'undefined' ? filesStore.getProvider.emails : [];
  };

  const searchProvidersTo = async (_object_code) => {
    await filesStore.fetchProviders(_object_code);
    emailsTo.value =
      typeof filesStore.getProvider.emails != 'undefined' ? filesStore.getProvider.emails : [];
  };

  const closeModal = () => {
    modal.value.open = false;
  };

  const responseFilesFrom = (files) => {
    filesFrom.value = files.map((item) => item.link);
  };

  // const responseFilesTo = (files) => {
  //   filesTo.value = files.map((item) => item.link);
  // };

  const showName = (file) => {
    let parts = file.split('/').splice(-1);
    return parts[0];
  };

  const showModalEmails = async (_emails) => {
    flagModal.value = true;
  };

  const proccessReservation = async () => {
    let cancellation = {};
    console.log('PROPS: ', props);

    if (props.type != 'new') {
      cancellation = {
        type: 'service',
        flag_email: 'cancellation',
        itinerary_id: props.from.id,
        services: params_cancellation.value,
        notas: notesFrom.value,
        attachments: filesFrom.value,
      };

      emit('onLoadReservation', {
        reservation: props,
        params: cancellation,
      });
    }

    if (props.type != 'cancellation') {
      props.to.forEach((reservation) => {
        reservation = {
          client_id: localStorage.getItem('client_id'),
          executive_id: Cookies.get(window.USER_ID),
          reference: filesStore.getFile.fileNumber,
          guests: reservation.passengers,
          reservations: [],
          reservations_services: filesStore.getFileItinerariesServicesReplace,
          entity: 'Cart',
          object_id: null,
        };

        let params = {
          type: 'service',
          flag_email: 'new',
          reservation_add: reservation,
          notas: notesTo.value,
          attachments: filesTo.value,
          file_id: filesStore.getFile.id,
        };

        emit('onLoadReservation', {
          reservation: props,
          params: params,
        });
      });
    }
  };

  const prevStep = () => {
    emit('onPrevStep');
  };
</script>
