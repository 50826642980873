import { defineStore } from 'pinia';
import { createFilePassengerCommunicationAdapter } from '@store/files/adapters';
import { viewCommunication } from '@/services/global';

export const useCommunicationsStore = defineStore({
  id: 'communications',
  state: () => ({
    loading: false,
    html: '',
    subject: '',
  }),
  getters: {
    isLoading: (state) => state.loading,
    getHtml: (state) => state.html,
    getSubject: (state) => state.subject,
  },
  actions: {
    previewCommunication(object_id, params, type, action) {
      this.loading = true;

      if (action != 'cancellation') {
        let guests = [];

        params.reservation_add.guests.forEach((guest) => {
          guests.push(createFilePassengerCommunicationAdapter(guest));
        });

        params.reservation_add.guests = guests;
      }

      return viewCommunication(object_id, params, type, action)
        .then(({ data }) => {
          this.loading = false;
          this.subject = data.data.subject;

          // Parsear el HTML..
          const parser = new DOMParser();
          const doc = parser.parseFromString(data.data.html, 'text/html');
          const bodyElement = doc.body;
          // Fin del Parseo HTML..

          this.html = bodyElement.innerHTML;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
});
