<template>
  <div class="files-edit">
    <div class="d-flex justify-content-between align-items-center pb-5">
      <div class="title">
        <font-awesome-icon :icon="['fas', 'stopwatch']" class="text-danger" />
        Crear servicio temporal
      </div>
      <div class="actions">
        <a-button
          class="btn-default mx-5 px-4"
          type="default"
          v-on:click="returnToProgram()"
          :loading="filesStore.isLoadingAsync"
          size="large"
        >
          Ir al programa
        </a-button>
        <a-button
          danger
          type="default"
          v-on:click="returnToProgram()"
          :loading="filesStore.isLoadingAsync"
          size="large"
        >
          Volver a modificar servicio
        </a-button>
      </div>
    </div>
    <a-row>
      <a-col :span="24">
        <div class="files-edit__fileinfo-center">
          <span class="files-edit__filealert">
            <a-alert class="px-4" type="warning" closable>
              <template #message>
                <WarningOutlined :style="{ fontSize: '18px', color: '#FFCC00' }" />
                <span class="text-warning">
                  El servicio temporal se crea a partir del servicio seleccionado. No se modifica o
                  reemplaza el servicio original
                </span>
              </template>
            </a-alert>
          </span>
        </div>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24" class="mt-5">
        <a-card class="files-edit__service-detail-card">
          <!-- Encabezado -->
          <div class="header-card">
            <a-row justify="space-around" align="middle">
              <a-col span="1">
                <font-awesome-icon :icon="['fas', 'bus']" size="xl" />
              </a-col>
              <a-col span="23">
                <div class="success-text-service">
                  <font-awesome-icon :icon="['far', 'circle-check']" size="lg" />
                  {{ serviceSelected.itinerary?.name }}
                </div>
              </a-col>
            </a-row>
            <a-row justify="space-around" align="middle" class="mt-4">
              <a-col span="1"></a-col>
              <a-col span="10">
                <div class="flex-row-service">
                  <div class="date-service">
                    <icon-calendar-ligth />
                    <span class="label-date">{{
                      formatDate(serviceSelected.itinerary?.date_in)
                    }}</span>
                  </div>
                  <div class="pax-service">
                    <span class="label-pax">pasajeros:</span>
                    <font-awesome-icon :icon="['fas', 'user']" />
                    <span class="label-adult">{{ serviceSelected.itinerary?.adults }}</span>
                    <font-awesome-icon :icon="['fas', 'child-reaching']" />
                    <span class="label-child">{{ serviceSelected.itinerary?.children }}</span>
                  </div>
                </div>
              </a-col>
              <a-col span="13">
                <div class="total-price-service">
                  <span class="label-price">Precio de venta:</span>
                  <span class="total-price">
                    $ {{ serviceSelected.itinerary?.total_amount || 0 }}
                  </span>
                  <span class="label-price-penalty">
                    <i class="bi bi-exclamation-triangle" style="font-size: 1.4rem"></i>
                    Penalidad por cancelación
                  </span>
                  <span class="total-price-penalty">
                    $ {{ serviceSelected.itinerary?.total_amount || 0 }}
                  </span>
                </div>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-divider style="height: 1px; background-color: #bbbdbf" />
              </a-col>
            </a-row>
          </div>
          <!-- Header Servicios Maestros -->
          <a-row justify="space-between" align="middle">
            <a-col :span="12">
              <span class="label-maestro">Servicios Maestros</span>
            </a-col>
            <a-col :span="12" style="text-align: right">
              <a-button type="link" class="btn-add-maestro" @click="showModalAddService()">
                <span>Agregar servicio maestro</span>
              </a-button>
            </a-col>
          </a-row>
          <!-- Listado de Servicios Maestros -->
          <div class="mt-5">
            <a-row
              justify="space-between"
              align="middle"
              class="mt-1"
              v-for="(service, index) in serviceSelected.itinerary?.services"
              :key="index"
            >
              <a-col :span="12" style="text-align: left">
                <span class="col-name-service">{{ service?.name }}</span>
              </a-col>
              <a-col :span="6" style="text-align: center">
                <span class="col-more-info-service" @click="showModalInfoMaster(service)">
                  Más información del compuesto
                </span>
              </a-col>
              <a-col :span="2" style="text-align: right">
                <span class="col-price">$ {{ service?.amount_cost }}</span>
              </a-col>
              <a-col :span="2" style="text-align: right">
                <span class="col-price-penalty">
                  <i class="bi bi-exclamation-triangle" style="font-size: 1.1rem"></i>
                  $ {{ service?.amount_cost }}
                </span>
              </a-col>
              <a-col :span="2" style="text-align: right">
                <span class="col-actions">
                  <font-awesome-icon
                    :icon="['fas', 'repeat']"
                    size="xl"
                    @click="showModalReplaceService"
                  />
                  <font-awesome-icon
                    :icon="['fas', 'trash-can']"
                    size="xl"
                    @click="deleteMasterService(service)"
                  />
                </span>
              </a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-form
      class="files-edit__form-service-temporary"
      :model="formState"
      ref="formRefServiceTemporary"
      layout="vertical"
      :rules="rules"
      @finish="onSaveServiceTemporary"
    >
      <a-row>
        <a-col :span="24">
          <a-card class="files-edit__new-service-temporary">
            <div class="header-card">
              <a-row class="mb-4">
                <a-col span="24">
                  <a-divider orientation="left" orientation-margin="0px" style="margin-bottom: 5px">
                    <span class="title-new-temporary">Nombre del servicio temporal</span>
                  </a-divider>
                  <span class="sub-title-new-temporary">
                    La selección de los datos conformarán el nombre del servicio
                  </span>
                </a-col>
              </a-row>
            </div>
            <a-row :gutter="16">
              <a-col span="8">
                <base-select
                  style="width: 100%"
                  name="fileId"
                  label="Tiempo"
                  placeholder="Selecciona..."
                  size="large"
                  :showSearch="true"
                  :filter-option="false"
                  :allowClear="true"
                  :options="[]"
                  v-model:value="formState.serviceTime"
                  @search="handleSearchFiles"
                  :loading="false"
                />
              </a-col>
              <a-col span="8">
                <base-select
                  style="width: 100%"
                  name="fileId"
                  label="Tipo"
                  placeholder="Selecciona..."
                  size="large"
                  :showSearch="true"
                  :filter-option="false"
                  :allowClear="true"
                  :options="[]"
                  v-model:value="formState.serviceType"
                  @search="handleSearchFiles"
                  :loading="false"
                />
              </a-col>
              <a-col span="8">
                <base-select
                  style="width: 100%"
                  name="fileId"
                  label="Privacidad"
                  placeholder="Selecciona..."
                  size="large"
                  :showSearch="true"
                  :filter-option="false"
                  :allowClear="true"
                  :options="[]"
                  v-model:value="formState.servicePrivacy"
                  @search="handleSearchFiles"
                  :loading="false"
                />
              </a-col>
            </a-row>
            <a-row class="mt-4" align="middle">
              <a-col span="5">
                <div class="label-complement">Texto complementario</div>
              </a-col>
              <a-col span="19">
                <base-input
                  v-model="formState.serviceSupplementaryText"
                  placeholder="Escribe aquí..."
                  name="description"
                  size="small"
                />
              </a-col>
            </a-row>
            <a-row class="mt-4" align="middle">
              <span class="files-edit__filealert">
                <a-alert class="px-4" type="success" closable>
                  <template #message>
                    <font-awesome-icon
                      :icon="['far', 'circle-check']"
                      size="lg"
                      :style="{ fontSize: '18px', color: '#398C03' }"
                    />
                    <span style="color: #398c03">
                      Full Day tour en compartido a las islas Palomino.
                    </span>
                  </template>
                </a-alert>
              </span>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-card class="files-edit__new-service-temporary">
            <div class="header-card">
              <a-row class="mb-4">
                <a-col span="24">
                  <a-divider orientation="left" orientation-margin="0px" style="margin-bottom: 5px">
                    <span class="title-new-temporary">Información adicional</span>
                  </a-divider>
                  <span class="sub-title-new-temporary">
                    La selección de los datos conformarán el nombre del servicio
                  </span>
                </a-col>
              </a-row>
            </div>
            <a-row :gutter="16" class="mb-5">
              <a-col span="4">
                <a-card class="language-card">
                  <a-card-grid class="language-card__grid">
                    <div class="label-lang-file">Idioma del file</div>
                    <div class="text-lang-file">Italiano</div>
                  </a-card-grid>
                </a-card>
              </a-col>
              <a-col span="20">
                <a-form-item label="Idiomas en los que desea ingresar la información del servicio">
                  <a-checkbox-group
                    name="checkbox-group"
                    size="large"
                    v-model:value="formState.languages"
                    :options="languagesOptions"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col span="24">
                <a-tabs v-model:activeKey="activeKey">
                  <a-tab-pane key="1" tab="Skeleton">
                    <span class="files-edit__filealert mt-4 mb-4">
                      <a-alert class="px-4" type="info" closable>
                        <template #message>
                          <font-awesome-icon
                            :icon="['far', 'circle-check']"
                            size="lg"
                            :style="{ fontSize: '18px', color: '#5C5AB4' }"
                          />
                          <span style="color: #2e2b9e">
                            Selecciona y escribe en la parte del Skeleton, original, del idioma que
                            necesite alguna modificación
                          </span>
                        </template>
                      </a-alert>
                    </span>
                    <div v-for="(language, index) in formState.languages" :key="index">
                      <a-form-item :label="`${getLanguageLabel(language)}`">
                        <a-textarea
                          :rows="4"
                          v-model:value="formState.languageTextsSkeleton[language]"
                        />
                      </a-form-item>
                    </div>
                  </a-tab-pane>
                  <a-tab-pane key="2" tab="Itineario">
                    <span class="files-edit__filealert mt-4 mb-4">
                      <a-alert class="px-4" type="info" closable>
                        <template #message>
                          <font-awesome-icon
                            :icon="['far', 'circle-check']"
                            size="lg"
                            :style="{ fontSize: '18px', color: '#5C5AB4' }"
                          />
                          <span style="color: #2e2b9e">
                            Selecciona y escribe en la parte del Itinerario, original, del idioma
                            que necesite alguna modificación
                          </span>
                        </template>
                      </a-alert>
                    </span>
                    <div v-for="(language, index) in formState.languages" :key="index">
                      <a-form-item :label="`${getLanguageLabel(language)}`">
                        <a-textarea
                          :rows="4"
                          v-model:value="formState.languageTextsItinerary[language]"
                        />
                      </a-form-item>
                    </div>
                  </a-tab-pane>
                </a-tabs>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" style="text-align: right; margin-top: 50px">
          <a-button
            class="btn-back text-600"
            type="default"
            :loading="filesStore.isLoading || filesStore.isLoadingAsync"
            size="large"
          >
            Atras
          </a-button>
          <a-button
            type="primary"
            class="btn-danger btn-continue mx-2 px-4 text-600"
            default
            @click="showModalConfirm"
            :loading="filesStore.isLoading || filesStore.isLoadingAsync"
            size="large"
          >
            Continuar
          </a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
  <ServiceTemporaryModalConfirm
    :is-open="modalIsOpenConfirmation"
    @update:is-open="modalIsOpenConfirmation = $event"
  />
  <ServiceTemporaryModalInformationMaster
    :is-open="modalIsOpenInfoMaster"
    @update:is-open="modalIsOpenInfoMaster = $event"
  />
  <ServiceTemporaryAddService
    :is-open="modalIsOpenAddService"
    @update:is-open="modalIsOpenAddService = $event"
  />
  <ServiceTemporaryReplaceService
    :is-open="modalIsOpenReplaceService"
    @update:is-open="modalIsOpenReplaceService = $event"
  />
</template>
<script setup lang="ts">
  import { onMounted, reactive, ref, watch } from 'vue';
  import { useFilesStore } from '@store/files';
  import { useLanguagesStore } from '@/stores/global/index.js';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import { WarningOutlined } from '@ant-design/icons-vue';
  import IconCalendarLigth from '@/quotes/components/icons/IconCalendarLight.vue';
  import BaseSelect from '@/components/files/reusables/BaseSelect.vue';
  import BaseInput from '@/components/files/reusables/BaseInput.vue';
  import ServiceTemporaryModalConfirm from '@/components/files/temporary/ServiceTemporaryModalConfirm.vue';
  import type { Service } from '@/components/files/temporary/interfaces/service.interface';
  import ServiceTemporaryModalInformationMaster from '@/components/files/temporary/ServiceTemporaryModalInformationMaster.vue';
  import ServiceTemporaryAddService from '@/components/files/temporary/ServiceTemporaryAddService.vue';
  import ServiceTemporaryReplaceService from '@/components/files/temporary/ServiceTemporaryReplaceService.vue';
  import { notification } from 'ant-design-vue';

  const loadingResources = ref(false);
  const activeKey = ref('1');
  const modalIsOpenConfirmation = ref(false);
  const modalIsOpenInfoMaster = ref(false);
  const modalIsOpenAddService = ref(false);
  const modalIsOpenReplaceService = ref(false);
  const serviceSelected = ref<Service>({});
  const languagesOptions = ref([]);
  const filesStore = useFilesStore();
  const languagesStore = useLanguagesStore();

  const formState = reactive({
    serviceTime: null,
    serviceType: null,
    servicePrivacy: null,
    serviceSupplementaryText: '',
    languages: ['es'],
    languageTextsSkeleton: {},
    languageTextsItinerary: {},
    skeleton: [],
    itineraries: [],
  });

  const rules = {
    serviceTime: [{ required: true, message: 'Debe seleccionar un tiempo' }],
    serviceType: [{ required: true, message: 'Debe seleccionar un tipo' }],
    servicePrivacy: [{ required: true, message: 'Debe seleccionar una privacidad' }],
    serviceSupplementaryText: [{ required: true, message: 'Debe ingresar un texto' }],
  };

  const onSaveServiceTemporary = () => {
    console.log('onSaveFileClone');
  };

  const showModalConfirm = () => {
    modalIsOpenConfirmation.value = true;
  };

  const showModalInfoMaster = (service: Service) => {
    console.log(service);
    modalIsOpenInfoMaster.value = true;
  };

  const showModalAddService = () => {
    modalIsOpenAddService.value = true;
  };

  const showModalReplaceService = () => {
    modalIsOpenReplaceService.value = true;
  };

  function formatDate(dateString: string | null): string {
    if (!dateString) return '';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(date);
  }

  watch(
    () => languagesStore.getLanguages,
    (newLanguages) => {
      languagesOptions.value = newLanguages;
    },
    { immediate: true }
  );

  watch(
    () => formState.languages,
    (newLanguages) => {
      // Asegurarse de que `languageTextsSkeleton` contenga solo los idiomas seleccionados
      const currentLanguages = { ...formState.languageTextsSkeleton };
      newLanguages.forEach((language) => {
        if (!currentLanguages[language]) {
          currentLanguages[language] = ''; // Inicializa el texto si no existe
        }
      });

      // Eliminar idiomas no seleccionados de `languageTextsSkeleton`
      Object.keys(currentLanguages).forEach((key) => {
        if (!newLanguages.includes(key)) {
          delete currentLanguages[key];
        }
      });

      formState.languageTextsSkeleton = currentLanguages;
    },
    { immediate: true }
  );

  function getLanguageLabel(language: string): string {
    const option = languagesOptions.value.find((opt) => opt.value === language);
    return option ? option.label : language;
  }

  // const replaceMasterService = () => {
  //   console.log('Replace Master Service');
  // };

  const deleteMasterService = (service: Service) => {
    const response = filesStore.removeServiceFromEdit(service.master_service_id);
    if (!response) {
      notification.success({
        message: 'Éxito',
        description: response.message,
      });
    } else {
      notification.error({
        message: 'Error',
        description: response.message,
      });
    }
  };

  onMounted(() => {
    serviceSelected.value = filesStore.getServiceEdit;
    if (serviceSelected.value.itinerary?.service_summary) {
      formState.languageTextsSkeleton['es'] = serviceSelected.value.itinerary.service_summary;
    }
    if (serviceSelected.value.itinerary?.service_itinerary) {
      formState.languageTextsItinerary['es'] = serviceSelected.value.itinerary.service_itinerary;
    }
    loadingResources.value = false;
    filesStore.finished();
  });
</script>

<style scoped lang="scss"></style>
