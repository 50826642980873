<template>
  <step-component :step="currentStep" :steps="stepsData" class="p-5" />
  <template v-if="isCurrentStep(0)">
    <ServiceTemporaryCreate />
  </template>
  <template v-else-if="isCurrentStep(1)">
    <!-- Contenido del segundo paso -->
  </template>

  <template v-else-if="isCurrentStep(2)">
    <!-- Contenido del tercer paso -->
  </template>
</template>
<script setup lang="ts">
  import StepComponent from '@/components/global/StepComponent.vue';
  import { ref } from 'vue';
  import ServiceTemporaryCreate from '@/components/files/temporary/ServiceTemporaryCreate.vue';

  const currentStep = ref(0);

  const isCurrentStep = (step) => currentStep.value === step;

  const stepsData = [
    { title: 'En proceso', description: 'Datos para servicio temporal' },
    { title: 'En espera', description: 'Comunicaciónes' },
    { title: 'En espera', description: 'Servcio temporal creado' },
  ];
</script>

<style scoped lang="scss"></style>
