<template>
  <template v-if="filesStore.isLoading">
    <div class="files-edit">
      <a-skeleton rows="1" active />
    </div>
    <div class="files-edit files-edit__border">
      <a-skeleton rows="1" active />
    </div>
    <div class="files-edit files-edit__border">
      <a-skeleton rows="1" active />
    </div>
  </template>
  <div class="files-edit" v-else>
    <a-steps :current="step">
      <a-step :title="showMessage(0)" description="Servicio y Hoteles con penalidad" />
      <a-step :title="showMessage(1)" description="Comunicaciones" />
      <a-step :title="showMessage(2)" description="Anulación completa" />
    </a-steps>

    <div v-if="step == 0">
      <div class="d-flex justify-content-between align-items-center mt-5">
        <h4 class="title"><i class="bi bi-x-circle text-danger"></i> Anular File</h4>
        <div class="actions">
          <a-button
            v-on:click="returnToProgram()"
            class="text-600"
            danger
            :loading="filesStore.isLoading || filesStore.isLoadingAsync"
            size="large"
          >
            Volver al Programa
          </a-button>
          <a-button
            type="primary"
            class="btn-danger ms-2 px-4text-600"
            v-on:click="nextStep()"
            default
            :loading="filesStore.isLoading || filesStore.isLoadingAsync"
            v-if="status_reason_id != ''"
            size="large"
          >
            Continuar
          </a-button>
        </div>
      </div>

      <div class="bg-light p-4 mt-3">
        <a-alert type="warning" showIcon class="mb-4">
          <template #icon><smile-outlined /></template>
          <template #message>
            <div class="text-warning">Debes seleccionar un motivo para la anulación del file.</div>
          </template>
        </a-alert>
        <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-item>
            <template #label>
              <span class="text-600">Motivo para la anulación del file</span>
              <b class="text-danger px-2">*</b>
            </template>
            <a-select
              :allowClear="false"
              class="w-100"
              v-model:value="status_reason_id"
              :showSearch="true"
              :fieldNames="{ label: 'name', value: 'id' }"
              :options="status_reasons"
            >
            </a-select>
          </a-form-item>
        </a-form>
        <div class="bg-white p-4 mb-4">
          <a-row type="flex" justify="center" align="middle" class="text-danger">
            <a-col>
              <span class="mx-3 h1">
                <font-awesome-icon :icon="['fa-solid', 'fa-circle-exclamation']" />
              </span>
            </a-col>
            <a-col>
              <span class="mx-3 text-700">Penalidad total por anular el File</span>
            </a-col>
            <a-col>
              <b class="mx-3 h1 text-800">{{
                formatNumber({ number: filesStore.getAllPenality })
              }}</b>
            </a-col>
            <a-col>
              <div class="mx-3 text-500">
                <p class="pb-0 mb-0">Penalidad total por pasajero adulto</p>
                <p class="pb-0 mb-0">Penalidad total por pasajero niño</p>
              </div>
            </a-col>
            <a-col>
              <div class="mx-3 text-800">
                <p class="pb-0 mb-0"><b>$ 360.00</b></p>
                <p class="pb-0 mb-0"><b>$ 360.00</b></p>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>

      <div class="bg-white bordered p-4 my-4">
        <FileHeader v-bind:data="filesStore.getFile" v-bind:editable="false" />
      </div>

      <div
        class="bg-white bordered p-4 py-4"
        v-if="filesStore.getPenalityHotels.length > 0 || filesStore.getPenalityServices.length > 0"
      >
        <template v-if="filesStore.getPenalityHotels.length > 0">
          <div class="subtitle mb-3">
            <a-row type="flex" align="middle">
              <i class="bi bi-exclamation-triangle text-warning" style="font-size: 1.5rem"></i>
              <div class="d-flex mx-2 text-dark-warning">Hoteles que generan penalidad</div>
            </a-row>
          </div>

          <template v-for="(_hotel, h) in filesStore.getPenalityHotels" :key="'penalty-hotel-' + h">
            <hotel-merge
              :show_communication="false"
              :title="false"
              :from="_hotel"
              :buttons="false"
            />
          </template>
        </template>

        <template v-if="filesStore.getPenalityServices.length > 0">
          <div class="subtitle mb-3">
            <a-row type="flex" align="middle">
              <i class="bi bi-exclamation-triangle text-warning" style="font-size: 1.5rem"></i>
              <div class="d-flex mx-2 text-dark-warning">Servicios que generan penalidad</div>
            </a-row>
          </div>

          <template
            v-for="(_service, s) in filesStore.getPenalityServices"
            :key="'penalty-service-' + s"
          >
            <service-merge
              :show_communication="false"
              :title="false"
              :from="_service"
              :buttons="false"
            />
          </template>
        </template>
      </div>

      <template v-else>
        <a-alert type="warning">
          <template #message>
            <div class="text-warning">
              No hay comunicaciones disponibles para hoteles y/o servicios.
            </div>
          </template>
        </a-alert>
      </template>

      <div class="my-3">
        <a-row type="flex" justify="end" align="middle">
          <a-col>
            <a-button
              type="default"
              class="px-4 text-600 btn-default"
              v-on:click="returnToProgram()"
              default
              :loading="filesStore.isLoading || filesStore.isLoadingAsync"
              size="large"
            >
              Cancelar
            </a-button>
            <a-button
              type="primary"
              default
              class="ms-2 px-4 text-600"
              v-on:click="nextStep()"
              :loading="filesStore.isLoading || filesStore.isLoadingAsync"
              v-if="status_reason_id != ''"
              size="large"
            >
              Continuar
            </a-button>
          </a-col>
        </a-row>
      </div>
    </div>

    <div v-if="step == 1">
      <div class="d-flex justify-content-between align-items-center mt-5 mb-5">
        <div class="title">
          <font-awesome-icon icon="fa-solid fa-comment-alt" class="text-danger" />
          Comunicación al proveedor
        </div>
        <div class="actions">
          <a-button
            v-on:click="returnToProgram()"
            class="text-600"
            danger
            :loading="filesStore.isLoading || filesStore.isLoadingAsync"
            size="large"
          >
            Volver al Programa
          </a-button>
        </div>
      </div>

      <template
        v-if="filesStore.getPenalityHotels.length > 0 || filesStore.getPenalityServices.length > 0"
      >
        <template v-if="filesStore.getPenalityHotels.length > 0">
          <template v-for="(_hotel, h) in filesStore.getPenalityHotels" :key="'penalty-hotel-' + h">
            <hotel-merge :show_communication="true" :title="false" :from="_hotel" :buttons="true" />
          </template>
        </template>

        <template v-if="filesStore.getPenalityServices.length > 0">
          <template v-for="(_service, h) in filesStore.getPenalityServices" :key="h">
            <service-merge
              :show_communication="true"
              :title="false"
              :from="_service"
              :buttons="true"
            />
          </template> </template
      ></template>
      <template v-else>
        <a-alert type="warning">
          <template #message>
            <div class="text-warning">
              No hay comunicaciones disponibles para hoteles y/o servicios, se puede proceder con la
              cancelación.
            </div>
          </template>
        </a-alert>
      </template>

      <div class="my-3">
        <a-row type="flex" justify="end" align="middle">
          <a-col>
            <a-button
              v-if="
                filesStore.getPenalityHotels.length > 0 || filesStore.getPenalityServices.length > 0
              "
              type="default"
              class="px-4 text-600 btn-default"
              v-on:click="prevStep()"
              default
              :loading="filesStore.isLoading || filesStore.isLoadingAsync"
              size="large"
            >
              Cancelar
            </a-button>
            <a-button
              type="primary"
              default
              class="ms-2 px-4 text-600"
              v-on:click="processReservation()"
              :loading="filesStore.isLoading || filesStore.isLoadingAsync"
              size="large"
            >
              Continuar
            </a-button>
          </a-col>
        </a-row>
      </div>
    </div>

    <div v-if="step == 2">
      <div class="mt-5 pt-5">
        <div class="text-center">
          <h2 class="text-danger text-800">
            Anulación de file {{ filesStore.getFile.fileNumber }} exitosa
          </h2>
          <div class="my-5">
            <a-row type="flex" justify="center" align="middle">
              <a-col>
                <svg
                  style="color: #1ed790"
                  class="feather feather-check-circle"
                  xmlns="http://www.w3.org/2000/svg"
                  width="5rem"
                  height="5rem"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                  <path d="M22 4 12 14.01l-3-3" />
                </svg>
              </a-col>
            </a-row>
          </div>
        </div>

        <div class="box-buttons mt-5">
          <a-row type="flex" justify="center" align="middle">
            <a-col>
              <a-button
                type="primary"
                class="px-4 text-600"
                v-on:click="returnToProgram()"
                default
                :loading="filesStore.isLoading || filesStore.isLoadingAsync"
                size="large"
              >
                Cerrar
              </a-button>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onBeforeMount, ref } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import {
    useFilesStore,
    useStatusesStore,
    useHaveInvoicesStore,
    useRevisionStagesStore,
  } from '@store/files';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import { formatNumber } from '@/utils/files.js';
  import FileHeader from '@/components/files/reusables/FileHeader.vue';
  import HotelMerge from '@/components/files/reusables/HotelMerge.vue';
  import ServiceMerge from '@/components/files/reusables/ServiceMerge.vue';

  const router = useRouter();
  const route = useRoute();
  const filesStore = useFilesStore();

  const statusesStore = useStatusesStore();
  const haveInvoicesStore = useHaveInvoicesStore();
  const revisionStagesStore = useRevisionStagesStore();

  const step = ref(0);
  const formState = ref({
    user: '',
    password: '',
  });
  const status_reason_id = ref('');
  const status_reasons = ref([]);

  const showMessage = (_step) => {
    let message = 'Finalizado';

    if (step.value < _step) {
      message = 'En espera';
    }

    if (step.value == _step) {
      message = 'En proceso';
    }

    return message;
  };

  const returnToProgram = () => {
    router.push({ name: 'files-edit', params: route.params });
  };

  onBeforeMount(async () => {
    const { id } = route.params;

    if (typeof filesStore.getFile.id == 'undefined') {
      await statusesStore.fetchAll();
      await haveInvoicesStore.fetchAll();
      await revisionStagesStore.fetchAll();

      await filesStore.getById({ id });
    }

    await filesStore.fetchStatusReasons();
    filesStore.calculatePenality();

    await filesStore.getStatusReasons.forEach(async (reason) => {
      if (reason.status_iso == 'XL') {
        status_reasons.value.push(reason);
      }
    });
    filesStore.finished();
  });

  const nextStep = () => {
    step.value++;
  };

  const prevStep = () => {
    step.value--;
  };

  const processReservation = () => {
    let params = {
      file_id: filesStore.getFile.id,
      notas: '',
      attachments: [],
      status_reason_id: status_reason_id.value,
    };

    filesStore
      .cancel(params)
      .then((response) => {
        console.log(response);
        nextStep();
      })
      .catch((err) => console.log(err));
  };
</script>
