import { defineStore } from 'pinia';
import type { MasterService } from '@/components/files/temporary/interfaces/master-service.interface';

export const useMasterServiceStore = defineStore('masterServiceStore', {
  state: () => ({
    selectedMasterServices: [] as MasterService[], // Lista de servicios seleccionados
  }),
  actions: {
    addService(service: MasterService) {
      if (
        !this.selectedMasterServices.some((s) => s.master_service_id === service.master_service_id)
      ) {
        service.isNew = true;
        service.isDeleted = false;
        service.isReplaced = false;
        service.replacedBy = null;
        this.selectedMasterServices.push(service);
      } else {
        console.error('El servicio ya existe en la lista.');
      }
    },
    removeService(masterServiceId: number) {
      this.selectedMasterServices = this.selectedMasterServices.filter(
        (service) => service.master_service_id !== masterServiceId
      );
    },
    clearSelectedMasterServices() {
      this.selectedMasterServices = [];
    },
  },
  getters: {
    getSelectedMasterServices: (state) => {
      return state.selectedMasterServices;
    },
    totalAmountCost: (state) => {
      const total = state.selectedMasterServices.reduce((acc, service) => {
        return acc + parseFloat(service.amount_cost) || 0;
      }, 0);
      return total.toFixed(2);
    },
  },
});
