import { defineStore } from 'pinia';
import { sendChunk, sendFiles } from '@/services/global';

export const useUploadsStore = defineStore({
  id: 'uploads',
  state: () => ({
    loading: false,
    loading_chunk: false,
    files: [],
  }),
  getters: {
    isLoading: (state) => state.loading,
    isLoadingChunk: (state) => state.loading_chunk,
  },
  actions: {
    sendChunkToLambda(params) {
      this.loading_chunk = true;

      return sendChunk(params)
        .then(({ data }) => {
          this.loading_chunk = false;
          return data;
        })
        .catch((error) => {
          console.log(error);
          this.loading_chunk = false;
          return error;
        });
    },
    sendToS3(params) {
      this.loading = true;

      return sendFiles(params)
        .then(({ data }) => {
          // console.log(data)
          this.loading = false;
          return data.links;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          return error;
        });
    },
  },
});
