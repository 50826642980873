<template>
  <a-row type="flex" justify="space-between" align="middle" style="gap: 10px">
    <a-col class="d-flex">
      <a-button @click="handleCloseModify()" class="text-600" danger type="primary" size="large">
        <svg
          class="d-block"
          xmlns="http://www.w3.org/2000/svg"
          width="1.2em"
          height="1.2em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M21 11H6.414l5.293-5.293l-1.414-1.414L2.586 12l7.707 7.707l1.414-1.414L6.414 13H21z"
          />
        </svg>
      </a-button>
    </a-col>
    <a-col flex="auto">
      <span> Modificación de Pasajeros </span>
    </a-col>
    <a-col class="d-flex" style="gap: 0.5rem">
      <a-button
        class="text-600"
        danger
        size="large"
        v-bind:disabled="selected.length == 0"
        @click="handleRemove()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="d-block"
          width="1.2rem"
          height="1.2rem"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.5"
          viewBox="0 0 24 24"
        >
          <path
            d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2M10 11v6M14 11v6"
          />
        </svg>
      </a-button>
      <a-button class="text-600" danger size="large" v-if="readonly" @click="readonly = !readonly">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="d-block"
          width="1.2rem"
          height="1.2rem"
          viewBox="0 0 24 24"
        >
          <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 5H6a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2v-5m-1.414-9.414a2 2 0 1 1 2.828 2.828L11.828 15H9v-2.828z"
          />
        </svg>
      </a-button>
      <a-button class="text-600" danger size="large" v-if="!readonly" @click="handleSave()">
        <i class="bi bi-floppy"></i>
      </a-button>
      <a-button class="text-600" danger @click="handleAddPassenger()" size="large">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="d-block"
          width="1.2em"
          height="1.2em"
          viewBox="0 0 24 24"
        >
          <g
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M19 8v6m3-3h-6" />
          </g>
        </svg>
      </a-button>
    </a-col>
  </a-row>

  <div class="mt-3">
    <a-table
      size="small"
      :showHeader="true"
      :columns="columns"
      :pagination="false"
      :data-source="filesStore.getModifyPaxs"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex == 'id'">
          <span class="cursor-pointer" v-on:click="togglePax(record.id)">
            <i
              :class="[
                'bi',
                selected.indexOf(record.id) > -1 ? 'bi-check-square text-danger' : 'bi-square',
              ]"
              style="font-size: 1rem"
            ></i>
          </span>
        </template>
        <template v-if="column.dataIndex === 'room_type'">
          <strong>{{ t(filesStore.showRoomType(record.room_type)) }}</strong>
        </template>
        <template v-if="column.dataIndex === 'accommodation'">
          <template v-if="readonly">
            <template v-for="pax in record.accommodation">
              <a-tag>{{ pax.name }} {{ pax.surnames }}</a-tag>
            </template>
          </template>
          <template v-else>
            <a-select
              mode="tags"
              id="passengers"
              style="width: 100%"
              v-model:value="accommodations[record.id]"
              max-tag-count="responsive"
              :options="
                filesStore.getFilePassengers.filter((pax) => pax.id !== record.file_passenger_id)
              "
            >
            </a-select>
          </template>
        </template>
        <template v-if="column.dataIndex === 'price_pax'">
          <strong>{{ record.type }}</strong>
        </template>
      </template>
    </a-table>
  </div>

  <a-modal v-model:visible="modal_add" title="Nuevo Pasajero" :width="400">
    <a-alert
      class="text-warning"
      type="warning"
      show-icon
      description="Al agregar nuevos pasajeros entrarás en el simulador de costos."
    >
    </a-alert>

    <div id="files-layout">
      <div style="width: 100%" class="mt-3">
        <a-row type="flex" justify="space-around">
          <div class="d-flex">
            <div class="passengers-group">
              <div class="passenger-input">
                <span class="cursor-pointer" @click="flag_adult = !flag_adult">
                  <i
                    :class="['bi', flag_adult ? 'bi-check-square-fill text-danger' : 'bi-square']"
                    style="font-size: 1.5rem"
                  ></i>
                </span>
                <span class="passenger-label cursor-pointer" @click="flag_adult = !flag_adult"
                  >Adultos</span
                >
                <template v-if="flag_adult">
                  <a-input v-model="form.adult" class="passenger-value border-gray-500" />
                  <div class="passenger-controls">
                    <a-button @click="() => increment('adult')" class="passenger-button">
                      <template #icon><font-awesome-icon :icon="['fas', 'chevron-up']" /></template>
                    </a-button>
                    <a-button @click="() => decrement('adult')" class="passenger-button">
                      <template #icon
                        ><font-awesome-icon :icon="['fas', 'chevron-down']"
                      /></template>
                    </a-button>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="passengers-group">
              <div class="passenger-input">
                <span class="cursor-pointer" @click="flag_child = !flag_child">
                  <i
                    :class="['bi', flag_child ? 'bi-check-square-fill text-danger' : 'bi-square']"
                    style="font-size: 1.5rem"
                  ></i>
                </span>
                <span class="passenger-label cursor-pointer" @click="flag_child = !flag_child"
                  >Niños</span
                >
                <template v-if="flag_child">
                  <a-input v-model="form.children" class="passenger-value border-gray-500" />
                  <div class="passenger-controls">
                    <a-button @click="() => increment('children')" class="passenger-button">
                      <template #icon><font-awesome-icon :icon="['fas', 'chevron-up']" /></template>
                    </a-button>
                    <a-button @click="() => decrement('children')" class="passenger-button">
                      <template #icon
                        ><font-awesome-icon :icon="['fas', 'chevron-down']"
                      /></template>
                    </a-button>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </a-row>
      </div>
    </div>

    <template #footer>
      <div class="text-center">
        <a-button
          type="default"
          default
          @click="handleCancel"
          v-bind:disabled="filesStore.isLoading || filesStore.isLoadingAsync"
          size="large"
        >
          Cancelar
        </a-button>
        <a-button
          type="primary"
          primary
          :loading="loading"
          @click="handleProcessAdd"
          v-bind:disabled="filesStore.isLoading || filesStore.isLoadingAsync"
          size="large"
        >
          Continuar
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script setup>
  import { onBeforeMount, ref } from 'vue';
  import { useFilesStore } from '@/stores/files';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n({
    useScope: 'global',
  });

  const filesStore = useFilesStore();
  const emit = defineEmits(['onCloseModify']);

  const modal_add = ref(false);
  const flag_adult = ref(false);
  const flag_child = ref(false);
  const selected = ref([]);
  const readonly = ref(true);
  const form = ref({
    adult: 0,
    child: 0,
  });

  const handleCloseModify = () => {
    emit('onCloseModify');
  };

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      sorter: false,
    },
    {
      title: 'Tipo de pasajero',
      dataIndex: 'type',
      sorter: true,
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Apellido',
      dataIndex: 'surnames',
      sorter: true,
    },
    {
      title: 'Tipo de habitación',
      dataIndex: 'room_type',
      sorter: true,
    },
    {
      title: 'Acomodo',
      dataIndex: 'accommodation',
      sorter: true,
    },
    {
      title: 'Fecha de nacimiento',
      dataIndex: 'date_birth',
      sorter: true,
    },
    {
      title: 'Costo por pax',
      dataIndex: 'cost_by_passenger',
      sorter: true,
    },
  ];

  const handleAddPassenger = () => {
    modal_add.value = true;
  };

  const handleCancel = () => {
    modal_add.value = false;
  };

  const handleProcessAdd = () => {};

  const handleRemove = () => {
    console.log('Paxs: ', selected.value);
  };

  const handleSave = () => {
    readonly.value = true;
  };

  const togglePax = (_pax) => {
    let index = selected.value.indexOf(_pax);
    if (index > -1) {
      selected.value.splice(index, 1);
    } else {
      selected.value.push(_pax);
    }
  };

  const accommodations = ref([]);

  const increment = (type) => {
    const currentValue = parseInt(form.value[type], 10) || 0;
    updatePassenger(type, currentValue + 1);
  };

  const decrement = (type) => {
    const currentValue = parseInt(form.value[type], 10) || 0;
    updatePassenger(type, currentValue - 1);
  };

  onBeforeMount(async () => {
    await filesStore.fetchModifyPaxs();
    console.log('Modify Paxs: ', filesStore.getModifyPaxs);

    for (const record of filesStore.getModifyPaxs) {
      accommodations.value[record.id] = record.accommodation.map((pax) => ({
        value: pax.id,
        label: `${pax.name} ${pax.surnames}`,
      }));
    }

    console.log(accommodations.value);
  });
</script>
