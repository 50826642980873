<template>
  <div class="d-block px-4 mt-3" v-if="props.title && props.type != 'new'">
    Estás a un paso de <b>eliminar</b> la reserva de hotel
    <template v-if="props.type == 'modification'"> y generar una <b>nueva reserva</b></template
    >.
  </div>
  <div class="box-merged">
    <a-row type="flex" align="middle" justify="start" class="mb-2 mt-3">
      <a-col :span="props.type == 'cancellation' ? 24 : 11" v-if="props.type != 'new'">
        <div class="box-left box-bordered p-4">
          <a-row
            align="middle"
            justify="end"
            class="mb-3"
            v-if="props.show_communication && emailsFrom.length > 0"
          >
            <span>Correo asociado a reservas:</span>
            <span class="mx-2 bordered">{{ emailsFrom[0] }}</span>
            <span class="cursor-pointer" v-on:click="showModalEmails(emailsFrom)">
              <i class="bi bi-plus-circle-dotted text-danger"></i>
            </span>
          </a-row>
          <div class="box-title">
            <a-row type="flex" align="middle" justify="space-between">
              <a-col class="d-flex">
                <span>
                  <i class="bi bi-building-fill"></i>
                </span>
                <span class="mx-2">
                  <b>{{ from.name }}</b>
                </span>
                <a-tag color="#c63838" class="mx-2">
                  {{ from.category }}
                </a-tag>
              </a-col>
              <a-col
                class="d-flex box-mutted"
                v-if="
                  filesStore.validateConfirmationCode(from) != '' &&
                  filesStore.validateConfirmationCode(from) != null
                "
              >
                Código de confirmación: <b>{{ filesStore.validateConfirmationCode(from) }}</b>
              </a-col>
            </a-row>
          </div>

          <div class="items">
            <template v-for="(room, r) in from.rooms" :key="r">
              <template v-for="(unit, u) in room.units" :key="u">
                <template v-if="selected.indexOf(unit.id) > -1">
                  <div class="bg-pink-stick p-3 mt-3 mb-4">
                    <a-row
                      type="flex"
                      align="middle"
                      :justify="props.type == 'cancellation' ? 'start' : 'space-between'"
                      style="gap: 10px"
                    >
                      <a-col class="d-flex">
                        <span class="me-1">
                          <i class="bi bi-calendar4"></i>
                        </span>
                        <b>{{ formatDate(from.date_in, 'DD/MM/YYYY') }}</b>
                        <b class="mx-1" style="font-size: 16px">|</b>
                        <b>{{ formatDate(from.date_out, 'DD/MM/YYYY') }}</b>
                      </a-col>
                      <a-col v-if="props.type == 'cancellation'" class="d-flex" style="gap: 7px">
                        <small class="d-flex ant-row-middle" style="gap: 7px">
                          <b>Room:</b>
                          <span class="text-uppercase">{{ room.room_type }}</span>
                        </small>
                        <small class="d-flex ant-row-middle" style="gap: 7px">
                          <b class="text-danger">
                            {{ room.total_adults / room.units.length }} adultos
                            {{ room.total_children / room.units.length }} niños
                          </b>
                        </small>
                      </a-col>
                      <a-col class="d-flex" style="gap: 7px">
                        <h6 class="bi bi-check2-circle text-success mb-0" v-if="unit.status"></h6>
                        <h6 class="bi bi-exclamation-triangle text-warning mb-0" v-else></h6>
                        <small class="text-700">
                          <template v-if="unit.penality > 0"> Cancelación con penalidad </template>
                          <template v-else> Cancelación sin penalidad </template>
                        </small>
                        <small class="text-success text-700"> $ {{ unit.penality }} </small>
                      </a-col>
                      <a-col
                        class="d-flex"
                        v-if="unit.confirmation_code != '' && unit.confirmation_code != null"
                      >
                        <small
                          v-if="filesStore.validateConfirmationCode(from) == ''"
                          class="box-mutted"
                        >
                          Código de confirmación: <b>{{ unit.confirmation_code }}</b>
                        </small>
                      </a-col>
                    </a-row>
                    <div class="mt-2 mx-1" v-if="props.type != 'cancellation'">
                      <a-row type="flex" align="middle" justify="space-between" style="gap: 7px">
                        <a-col class="d-flex" style="gap: 7px">
                          <b>Room:</b>
                          <span class="text-uppercase">{{ room.room_type }}</span>
                        </a-col>
                        <a-col class="d-flex" style="gap: 7px">
                          <b class="text-danger"
                            >{{ room.total_adults / room.units.length }} adultos
                            {{ room.total_children / room.units.length }} niños</b
                          >
                        </a-col>
                      </a-row>
                    </div>
                  </div>
                </template>
              </template>
            </template>
          </div>

          <a-row align="middle" type="flex" justify="space-between" class="mx-2">
            <a-col>
              <div
                v-bind:class="[
                  'd-flex cursor-pointer',
                  !showNotesFrom ? 'text-dark-gray' : 'text-danger',
                ]"
                @click="showNotesFrom = !showNotesFrom"
              >
                <template v-if="showNotesFrom">
                  <i
                    class="bi bi-check-square-fill text-danger d-flex"
                    style="font-size: 1.5rem"
                  ></i>
                </template>
                <template v-else>
                  <i class="bi bi-square d-flex" style="font-size: 1.5rem"></i>
                </template>
                <span class="mx-2">Agregar nota al proveedor</span>
              </div>
            </a-col>
          </a-row>
          <div class="mb-3 mx-2" v-if="showNotesFrom">
            <template v-if="lockedNotesFrom">
              <a-card style="width: 100%" class="mt-3" :headStyle="{ background: black }">
                <template #title> Nota en reserva para el proveedor: </template>
                <template #extra>
                  <a href="javascript:;" @click="lockedNotesFrom = false" class="text-danger">
                    <i class="bi bi-pencil"></i>
                  </a>
                </template>
                <p class="mb-2">
                  <b>{{ notesFrom }}</b>
                </p>
                <template v-for="(file, f) in filesFrom" :key="f">
                  <a-row align="middle" class="mb-2">
                    <i class="bi bi-paperclip"></i>
                    <a :href="file" target="_blank" class="text-dark mx-1">
                      {{ showName(file) }}
                    </a>
                  </a-row>
                </template>
              </a-card>
            </template>

            <template v-if="!lockedNotesFrom">
              <p class="text-danger my-2">Nota para el proveedor:</p>
              <a-row align="top" justify="space-between">
                <a-col flex="auto">
                  <a-textarea
                    v-model:value="notesFrom"
                    placeholder="Escribe una nota para el proveedor que podrás visualizar en la comunicación"
                    :auto-size="{ minRows: 2 }"
                  />
                </a-col>
                <a-col class="mx-2">
                  <file-upload
                    v-bind:folder="'communications'"
                    @onResponseFiles="responseFilesFrom"
                  />
                </a-col>
                <a-col>
                  <a-button
                    danger
                    type="default"
                    size="large"
                    :disabled="!(notesFrom != '' || filesFrom.length > 0)"
                    class="d-flex ant-row-middle text-600"
                    @click="lockedNotesFrom = true"
                    :loading="communicationsStore.isLoading"
                  >
                    <i class="bi bi-floppy"></i>
                  </a-button>
                </a-col>
              </a-row>
            </template>
          </div>
          <template v-if="lockedNotesFrom || !showNotesFrom">
            <a-row align="middle" type="flex" justify="end" class="mx-2">
              <a-col class="ant-row-end">
                <a-button
                  danger
                  type="default"
                  class="d-flex ant-row-middle text-600"
                  @click="showCommunicationFrom()"
                  :loading="communicationsStore.isLoading || communicationsStore.isLoadingAsync"
                >
                  <i
                    class="bi bi-search"
                    v-if="!(communicationsStore.isLoading || communicationsStore.isLoadingAsync)"
                  ></i>
                  <span class="mx-2">Ver comunicación</span>
                </a-button>
              </a-col>
            </a-row>
          </template>
        </div>
      </a-col>
      <a-col
        :span="2"
        class="text-center merge-icon"
        v-if="props.type != 'new' && props.type != 'cancellation'"
      >
        <i class="bi bi-arrow-right-circle" style="font-size: 4rem"></i>
      </a-col>
      <a-col :span="props.type == 'new' ? 24 : 11">
        <div class="box-left box-bordered p-4" v-for="(hotel, h) in to" :key="h">
          <a-row align="middle" justify="end" class="mb-3" v-if="emailsTo[h].length > 0">
            <span>Correo asociado a reservas:</span>
            <span class="mx-2 bordered">{{ emailsTo[h][0] }}</span>
            <span class="cursor-pointer" v-on:click="showModalEmails(emailsTo[h])">
              <i class="bi bi-plus-circle-dotted text-danger"></i>
            </span>
          </a-row>
          <div class="box-title">
            <a-row type="flex" align="middle" justify="space-between">
              <a-col class="d-flex">
                <span>
                  <i class="bi bi-building-fill"></i>
                </span>
                <span class="mx-2">
                  <b>{{ hotel.name }}</b>
                </span>
                <a-tag :color="hotel.color_class">
                  {{ hotel.class }}
                </a-tag>
              </a-col>
            </a-row>
          </div>

          <div class="items" v-for="(room, r) in hotel.rooms" :key="r">
            <div class="bg-purple-stick p-3 mt-3 mb-4" v-for="(rate, r) in room.rates" :key="r">
              <a-row
                type="flex"
                align="middle"
                :justify="props.type == 'cancellation' ? 'start' : 'space-between'"
                style="gap: 10px"
              >
                <a-col class="d-flex">
                  <span class="me-1">
                    <i class="bi bi-calendar4"></i>
                  </span>
                  <b>{{ formatDate(rate.rate[0].amount_days[0].date, 'DD/MM/YYYY') }}</b>
                  <template v-if="rate.rate[0].amount_days.length > 1">
                    <b class="mx-1" style="font-size: 16px">|</b>
                    <b>{{
                      formatDate(
                        rate.rate[0].amount_days[rate.rate[0].amount_days.length - 1].date,
                        'DD/MM/YYYY'
                      )
                    }}</b>
                  </template>
                </a-col>
                <a-col v-if="props.type == 'new'" class="d-flex" style="gap: 7px">
                  <small class="d-flex ant-row-middle" style="gap: 7px">
                    <b>Room:</b>
                    <span class="text-uppercase">{{ room.room_type }}</span>
                  </small>
                  <small class="d-flex ant-row-middle" style="gap: 7px">
                    <b class="text-danger">
                      {{ filter.quantity_persons_rooms[0].adults }} adultos
                      {{ filter.quantity_persons_rooms[0].child }} niños
                    </b>
                  </small>
                </a-col>
                <a-col class="d-flex" style="gap: 7px">
                  <h6
                    class="d-flex bi bi-check2-circle text-success mb-0"
                    v-if="rate.onRequest == 0"
                  ></h6>
                  <h6 class="d-flex bi bi-exclamation-triangle text-warning mb-0" v-else></h6>
                  <small class="text-700 text-success" v-if="rate.onRequest == 0">
                    Confirmada
                  </small>
                  <small class="text-700 text-success">$ {{ rate.total }}</small>
                </a-col>
              </a-row>
              <div class="mt-2 mx-1" v-if="props.type != 'new'">
                <a-row type="flex" align="middle" justify="space-between">
                  <a-col class="d-flex" style="gap: 7px">
                    <b>Room:</b>
                    <span class="text-uppercase">{{ room.room_type }}</span>
                  </a-col>
                  <a-col class="d-flex" style="gap: 7px">
                    <b class="text-danger"
                      >{{ filter.quantity_persons_rooms[0].adults }} adultos
                      {{ filter.quantity_persons_rooms[0].child }} niños</b
                    >
                  </a-col>
                </a-row>
              </div>
            </div>

            <a-row align="middle" type="flex" justify="space-between" class="mx-2">
              <a-col>
                <div
                  v-bind:class="[
                    'd-flex cursor-pointer',
                    !showNotesTo ? 'text-dark-gray' : 'text-danger',
                  ]"
                  @click="showNotesTo = !showNotesTo"
                >
                  <template v-if="showNotesTo">
                    <i
                      class="bi bi-check-square-fill text-danger d-flex"
                      style="font-size: 1.5rem"
                    ></i>
                  </template>
                  <template v-else>
                    <i class="bi bi-square d-flex" style="font-size: 1.5rem"></i>
                  </template>
                  <span class="mx-2">Agregar nota al proveedor</span>
                </div>
              </a-col>
            </a-row>

            <div class="mb-3 mx-2" v-if="showNotesTo">
              <template v-if="lockedNotesTo">
                <a-card style="width: 100%" class="mt-3" :headStyle="{ background: black }">
                  <template #title> Nota en reserva para el proveedor: </template>
                  <template #extra>
                    <a href="javascript:;" @click="lockedNotesTo = false" class="text-danger">
                      <i class="bi bi-pencil"></i>
                    </a>
                  </template>
                  <p class="mb-2">
                    <b>{{ notesTo }}</b>
                  </p>
                  <template v-for="(file, f) in filesTo" :key="f">
                    <a-row align="middle" class="mb-2">
                      <i class="bi bi-paperclip"></i>
                      <a :href="file" target="_blank" class="text-dark mx-1">
                        {{ showName(file) }}
                      </a>
                    </a-row>
                  </template>
                </a-card>
              </template>

              <template v-if="!lockedNotesTo">
                <p class="text-danger my-2">Nota para el proveedor:</p>
                <a-row align="top" justify="space-between">
                  <a-col flex="auto">
                    <a-textarea
                      v-model:value="notesTo"
                      placeholder="Escribe una nota para el proveedor que podrás visualizar en la comunicación"
                      :auto-size="{ minRows: 2 }"
                    />
                  </a-col>
                  <a-col class="mx-2">
                    <file-upload
                      v-bind:folder="'communications'"
                      @onResponseFiles="responseFilesTo"
                    />
                  </a-col>
                  <a-col>
                    <a-button
                      danger
                      type="default"
                      size="large"
                      :disabled="!(notesTo != '' || filesTo.length > 0)"
                      class="d-flex ant-row-middle"
                      @click="lockedNotesTo = true"
                      :loading="communicationsStore.isLoading || communicationsStore.isLoadingAsync"
                    >
                      <i class="bi bi-floppy"></i>
                    </a-button>
                  </a-col>
                </a-row>
              </template>
            </div>

            <template v-if="lockedNotesFrom || !showNotesFrom">
              <a-row align="middle" type="flex" justify="end" class="mx-2">
                <a-col class="ant-row-end">
                  <a-button
                    danger
                    type="default"
                    class="d-flex ant-row-middle text-600"
                    v-if="type_to != ''"
                    @click="showCommunicationTo(h)"
                    :loading="communicationsStore.isLoading || communicationsStore.isLoadingAsync"
                  >
                    <i
                      class="bi bi-search"
                      v-if="!(communicationsStore.isLoading || communicationsStore.isLoadingAsync)"
                    ></i>
                    <span class="mx-2">Ver comunicación</span>
                  </a-button>
                  <a-button
                    danger
                    type="default"
                    class="d-flex ant-row-middle text-600"
                    v-else
                    @click="showCommunicationFrom()"
                    :loading="communicationsStore.isLoading || communicationsStore.isLoadingAsync"
                  >
                    <i
                      class="bi bi-search"
                      v-if="!(communicationsStore.isLoading || communicationsStore.isLoadingAsync)"
                    ></i>
                    <span class="mx-2">Ver comunicación</span>
                  </a-button>
                </a-col>
              </a-row>
            </template>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>

  <div class="box-buttons" v-if="props.buttons">
    <a-row type="flex" justify="end" align="middle">
      <a-col>
        <a-button
          type="default"
          class="btn-default mx-2 px-4 text-600"
          v-on:click="prevStep()"
          default
          :disabled="filesStore.isLoading || filesStore.isLoadingAsync"
          size="large"
        >
          Atrás
        </a-button>
        <a-button
          type="primary"
          class="mx-2 px-4 text-600"
          v-if="selected.length > 0 || type == 'new'"
          v-on:click="proccessReservation()"
          default
          :disabled="filesStore.isLoading || filesStore.isLoadingAsync"
          size="large"
        >
          <template v-if="type == 'cancellation'">Continuar</template>
          <template v-else>Reservar</template>
        </a-button>
      </a-col>
    </a-row>
  </div>

  <a-modal
    v-model:open="modal.open"
    :title="modal.subject"
    :width="720"
    :closable="true"
    :maskClosable="false"
  >
    <template #footer>
      <a-row align="middle" justify="center">
        <a-col>
          <a-button
            key="button"
            type="primary"
            default
            size="large"
            class="text-600"
            @click="closeModal"
            >Cerrar</a-button
          >
        </a-col>
      </a-row>
    </template>
    <div v-html="modal.html"></div>
  </a-modal>
</template>

<script setup>
  import { onBeforeMount, ref } from 'vue';
  import { useFilesStore } from '@store/files';
  import { useCommunicationsStore } from '@/stores/global';
  import { formatDate } from '@/utils/files.js';
  import FileUpload from '@/components/global/FileUploadComponent.vue';

  const emit = defineEmits(['onPrevStep', 'onNextStep', 'onLoadReservation']);

  const props = defineProps({
    buttons: {
      type: Boolean,
      default: () => true,
    },
    show_communication: {
      type: Boolean,
      default: () => true,
    },
    title: {
      type: Boolean,
      default: () => true,
    },
    token_search_hotels: {
      type: String,
      default: () => '',
    },
    from: {
      type: Object,
      default: () => {},
    },
    to: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Array,
      default: () => [],
    },
    date_from: {
      type: String,
      default: () => '',
    },
    date_to: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => '',
    },
  });

  onBeforeMount(async () => {
    console.log('PROPS: ', props);

    if (props.type == 'new') {
      type_to.value = 'new';
    }

    if (props.type == 'modification') {
      if (props.from.object_code != props.to[0].code) {
        // Es son hoteles diferentes.. update
        type_from.value = 'cancellation';
        type_to.value = 'new';
      } else {
        type_from.value = 'modification';
        type_to.value = '';
      }
    }

    if (props.type == 'cancellation') {
      type_from.value = 'cancellation';
      type_to.value = '';
    }

    filter.value = filesStore.getSearchParametersHotels;
    client_id.value = localStorage.getItem('client_id');

    if (props.from != undefined && props.from.object_code != undefined) {
      await searchProvidersFrom(props.from.object_code);
    }

    if (props.to != undefined) {
      props.to.forEach(async (_provider, p) => {
        emailsTo.value.push([]);
        await searchProvidersTo(_provider.code, p);
      });
    }
  });

  const filesStore = useFilesStore();
  const communicationsStore = useCommunicationsStore();

  const filter = ref({});
  const type_from = ref('');
  const type_to = ref('');

  const flagModal = ref(false);
  const emailsFrom = ref([]);
  const emailsTo = ref([]);

  const showNotesFrom = ref(false);
  const showNotesTo = ref(false);

  const lockedNotesFrom = ref(false);
  const lockedNotesTo = ref(false);

  const notesFrom = ref('');
  const filesFrom = ref([]);

  const notesTo = ref('');
  const filesTo = ref([]);
  const client_id = ref('');

  const modal = ref({
    open: false,
    html: '',
    subject: '',
  });

  const showCommunicationFrom = async () => {
    let rooms = [];

    props.from.rooms.forEach((room) => {
      let units = [];

      room.units.forEach((unit) => {
        if (props.selected.indexOf(unit.id) > -1) {
          units.push(unit.id);
        }
      });

      if (units.length > 0) {
        rooms.push({
          id: room.id,
          units: units,
        });
      }
    });

    let params = {
      rooms: rooms,
      notas: notesFrom.value,
      attachments: filesFrom.value,
    };

    await communicationsStore.previewCommunication(
      'itineraries/' + props.from.id,
      params,
      'hotel',
      type_from.value
    );

    modal.value.html = communicationsStore.getHtml;
    modal.value.subject = communicationsStore.getSubject;
    modal.value.open = true;
  };

  const showCommunicationTo = async (_index) => {
    let rates = [];
    let hotel = props.to[_index];

    hotel.rooms.forEach((room) => {
      room.rates.forEach((rate) => {
        rates.push({
          token_search: hotel.token_search,
          hotel_id: hotel.id,
          best_option: hotel.best_option_taken,
          rate_plan_room_id: rate.rateId,
          guest_note: null,
          date_from: filter.value.date_from,
          date_to: filter.value.date_to,
          quantity_adults: filter.value.quantity_persons_rooms[0].adults,
          quantity_child: filter.value.quantity_persons_rooms[0].child,
          child_ages: filter.value.quantity_persons_rooms[0].ages_child,
        });
      });
    });

    let params = {
      reservation_add: {
        client_id: client_id.value,
        file_code: filesStore.getFile.fileNumber,
        reference: null,
        send_mail: 0,
        guests: props.to[0].passengers,
        reservations: rates,
      },
      notas: notesTo.value,
      attachments: filesTo.value,
    };

    await communicationsStore.previewCommunication(
      filesStore.getFile.id,
      params,
      'hotel',
      type_to.value
    );

    modal.value.html = communicationsStore.getHtml;
    modal.value.subject = communicationsStore.getSubject;
    modal.value.open = true;
  };

  const searchProvidersFrom = async (_object_code) => {
    await filesStore.fetchProviders(_object_code);
    emailsFrom.value =
      typeof filesStore.getProvider.emails != 'undefined' ? filesStore.getProvider.emails : [];
  };

  const searchProvidersTo = async (_object_code, p) => {
    await filesStore.fetchProviders(_object_code);
    emailsTo.value[p] =
      typeof filesStore.getProvider.emails != 'undefined' ? filesStore.getProvider.emails : [];
  };

  const closeModal = () => {
    modal.value.open = false;
  };

  const responseFilesFrom = (files) => {
    filesFrom.value = files.map((item) => item.link);
  };

  const responseFilesTo = (files) => {
    filesTo.value = files.map((item) => item.link);
  };

  const showName = (file) => {
    let parts = file.split('/').splice(-1);
    return parts[0];
  };

  const showModalEmails = async (_emails) => {
    flagModal.value = true;
  };

  const proccessReservation = async () => {
    let cancellation = {};
    let reservation = {};

    if (props.type != 'new') {
      let rooms = [];

      props.from.rooms.forEach((room) => {
        let units = [];

        room.units.forEach((unit) => {
          if (props.selected.indexOf(unit.id) > -1) {
            units.push(unit.id);
          }
        });

        if (units.length > 0) {
          rooms.push({
            id: room.id,
            units: units,
          });
        }
      });

      cancellation = {
        itinerary_id: filesStore.getFileItinerary.id,
        rooms: rooms,
        notas: notesFrom.value,
        attachments: filesFrom.value,
      };

      if (props.type == 'cancellation') {
        cancellation = {
          ...cancellation,
          type: 'hotel',
          flag_email: 'cancellation',
          file_id: filesStore.getFile.id,
        };

        emit('onLoadReservation', {
          reservation: props,
          params: cancellation,
        });
      }
    }

    if (props.type != 'cancellation') {
      props.to.forEach((hotel) => {
        let rates = [];

        hotel.rooms.forEach((room) => {
          room.rates.forEach((rate) => {
            rates.push({
              token_search: hotel.token_search,
              hotel_id: hotel.id,
              best_option: hotel.best_option_taken,
              rate_plan_room_id: rate.rateId,
              guest_note: null,
              date_from: filter.value.date_from,
              date_to: filter.value.date_to,
              quantity_adults: filter.value.quantity_persons_rooms[0].adults,
              quantity_child: filter.value.quantity_persons_rooms[0].child,
              child_ages: filter.value.quantity_persons_rooms[0].ages_child,
            });
          });
        });

        reservation = {
          client_id: client_id.value,
          file_code: filesStore.getFile.fileNumber,
          reference: null,
          send_mail: 0,
          guests: filesStore.formatGuests(props.to[0].passengers),
          reservations: rates,
        };

        let params = {
          type: 'hotel',
          flag_email: props.type,
          reservation_add: reservation,
          notas: notesTo.value,
          attachments: filesTo.value,
          file_id: filesStore.getFile.id,
          cancellation: cancellation,
        };

        emit('onLoadReservation', {
          reservation: props,
          params: params,
        });
      });
    }
  };

  const prevStep = () => {
    emit('onPrevStep');
  };
</script>
