import { defineStore } from 'pinia';
import { fetchExecutives } from '../../services/files';

import { createExecutiveAdapter } from '@store/files/adapters';

export const useExecutivesStore = defineStore({
  id: 'executives',
  state: () => ({
    loading: false,
    executives: [],
  }),
  getters: {
    isLoading: (state) => state.loading,
    getExecutives: (state) => state.executives,
  },
  actions: {
    fetchAll(search = '') {
      this.loading = true;
      return fetchExecutives(search)
        .then(({ data }) => {
          this.executives = Object.entries(data.data.executives).map((e) =>
            createExecutiveAdapter(e)
          );
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
});
