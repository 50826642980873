<template>
  <div>
    <a-modal
      class="file-modal"
      :open="isOpen"
      @cancel="handleCancel"
      :footer="null"
      :keyboard="!isSaving"
      :maskClosable="!isSaving"
      :focusTriggerAfterClose="false"
      @open="handleModalOpen"
    >
      <template #title>
        <div class="title-confirm">
          <div class="title-confirm__number">2</div>
          Comunicaciónes
        </div>
        <hr />
      </template>
      <a-row :gutter="16">
        <a-col :span="24">
          <p class="text-center title-confirm__subtitle">
            Está a punto de crear un servicio temporal
          </p>
        </a-col>
        <a-col :span="24">
          <a-alert class="text-info" type="info" show-icon closable>
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="feather feather-info"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M12 16v-4M12 8h.01" />
              </svg>
            </template>
            <template #message>
              <div class="text-info">Paso 2 omitido</div>
            </template>
            <template #description>
              <div class="text-info">
                Los servicios maestros que componen la equivalencia no poseen comunicaciones
              </div>
            </template>
          </a-alert>
        </a-col>
        <a-col :span="24">
          <p class="text-center text-confirm">¿Desea continuar?</p>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="24" class="text-right mt-4">
          <a-button @click="handleCancel" class="btn-cancel" :disabled="loading">
            Cancelar
          </a-button>
          <a-button class="btn-save" html-type="submit" :loading="loading"> Continuar</a-button>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>
<script setup lang="ts">
  defineProps({
    isOpen: {
      type: Boolean,
      required: true,
    },
  });

  const emit = defineEmits(['update:isOpen', 'submit']);

  const handleCancel = () => {
    emit('update:isOpen', false);
  };
</script>
<style scoped lang="scss"></style>
