import axios from 'axios';
import Cookies from 'js-cookie';

window.TOKEN_COGNITO_KEY = import.meta.env.VITE_TOKEN_KEY_COGNITO_LIMATOUR;
window.AMAZON_SQS = import.meta.env.VITE_APP_AMAZON_SQS_URL;
const accessTokenCognito = Cookies.get(window.TOKEN_COGNITO_KEY);

const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${accessTokenCognito}`,
};

export default axios.create({ headers, timeout: 35000, baseURL: window.AMAZON_SQS });
