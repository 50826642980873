<template>
  <div class="bg-pink-stick p-5 mt-5">
    <div class="d-flex justify-content-between align-items-center me-5 pe-5">
      <div class="subtitle">
        <span>
          <font-awesome-icon :icon="['fa-solid', 'fa-hotel']" />
        </span>
        <span class="mx-2 text-uppercase">
          <b>{{ filesStore.getFileItinerary.name }}</b>
        </span>
        <span class="mx-2">
          <a-tag color="#c63838">
            {{ filesStore.getFileItinerary.category }}
          </a-tag>
        </span>
      </div>
      <div class="d-flex col text-dark-gray" style="gap: 7px">
        <CalendarOutlined />
        <b>{{ formatDate(filesStore.getFileItinerary.date_in, 'DD/MM/YYYY') }}</b>
        <b style="font-size: 18px">|</b>
        <b>{{ formatDate(filesStore.getFileItinerary.date_out, 'DD/MM/YYYY') }}</b>
      </div>
    </div>

    <template v-for="(room, r) in filesStore.getFileItinerary.rooms" :key="'room-' + r">
      <a-row type="flex" align="middle" justify="start" class="mb-2 mt-3">
        <a-col flex="auto">
          <b>{{ t('global.label.room') }}:</b>
          <span class="text-uppercase">{{ room.room_name }}</span>
        </a-col>
        <a-col flex="auto">
          <b>{{ t('global.label.nights') }}:</b>
          <b class="text-danger">{{ room.units[0].nights.length }}</b>
        </a-col>
        <a-col flex="auto">
          <b>{{ t('global.label.units') }}:</b> <b class="text-danger">{{ room.units.length }}</b>
        </a-col>
        <a-col flex="auto">
          <div class="d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="24"
              height="24"
              class="svg-danger"
            >
              <path
                d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"
              />
            </svg>
            <b class="mx-2">{{ room.rate_plan_name }}</b>
            <b class="text-danger">$ {{ room.amount_sale }}</b>
          </div>
        </a-col>
        <a-col style="margin-top: -0.5rem">
          <a href="javascript:;" @click="handleSelected('room', room)">
            <template v-if="validateSelected(room.units) == 0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                width="30"
                height="30"
                class="text-danger bi-square"
              >
                <path
                  d="M384 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"
                />
              </svg>
            </template>
            <template v-else>
              <template v-if="validateSelected(room.units) == room.units.length">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  width="30"
                  height="30"
                  class="text-danger bi-checked"
                >
                  <path
                    d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
                  />
                </svg>
              </template>
              <template v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  width="30"
                  height="30"
                  class="text-danger bi-minus"
                >
                  <path
                    d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm88 200H296c13.3 0 24 10.7 24 24s-10.7 24-24 24H152c-13.3 0-24-10.7-24-24s10.7-24 24-24z"
                  />
                </svg>
              </template>
            </template>
          </a>
        </a-col>
      </a-row>
      <a-row type="flex" class="mt-3 mb-2">
        <a-col flex="auto" class="d-flex">
          <svg
            class="feather feather-git-pull-request"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
          >
            <circle cx="18" cy="18" r="3" />
            <circle cx="6" cy="6" r="3" />
            <path d="M13 6h3a2 2 0 0 1 2 2v7M6 9v12" />
          </svg>
          <b class="mx-1"> {{ t('global.label.units') }} </b>
        </a-col>
      </a-row>
      <template v-for="(unit, u) in room.units" :key="u">
        <a-row type="flex" justify="center">
          <a-col flex="auto">
            <span class="text-uppercase"> {{ room.room_name }} - {{ room.room_type }} </span>
          </a-col>
          <a-col flex="auto">
            <a-row type="flex" justify="end" align="middle">
              <a-col class="d-flex me-2">
                <svg
                  v-if="room.confirmation_status"
                  style="color: #1ed790"
                  class="feather feather-check-circle"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                  <path d="M22 4 12 14.01l-3-3" />
                </svg>
                <svg
                  v-else
                  style="color: #ffcc00"
                  class="feather feather-alert-triangle"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0zM12 9v4M12 17h.01"
                  />
                </svg>
              </a-col>
              <a-col class="d-flex">
                <div class="d-flex me-2">
                  <b>${{ unit.amount_cost }}</b>
                  <files-edit-field-static :inline="true" :hide-content="false">
                    <template #label>
                      <svg
                        v-if="room.room_amount.file_amount_type_flag_id === 1"
                        style="margin: 7px 0; color: #ffcc00; cursor: pointer"
                        class="feather feather-lock"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                        viewBox="0 0 24 24"
                      >
                        <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
                        <path d="M7 11V7a5 5 0 0 1 10 0v4" />
                      </svg>
                      <svg
                        v-if="room.room_amount.file_amount_type_flag_id === 2"
                        style="margin: 7px 0; color: #3d3d3d; cursor: pointer"
                        class="feather feather-lock"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                        viewBox="0 0 24 24"
                      >
                        <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
                        <path d="M7 11V7a5 5 0 0 1 10 0v4" />
                      </svg>
                      <svg
                        v-if="room.room_amount.file_amount_type_flag_id === 3"
                        style="margin: 7px 0; color: #c4c4c4; cursor: pointer"
                        class="feather feather-lock"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                        viewBox="0 0 24 24"
                      >
                        <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
                        <path d="M7 11V7a5 5 0 0 1 10 0v4" />
                      </svg>
                    </template>
                    <template #popover-content>
                      {{ room.room_amount.file_amount_type_flag.description }}
                    </template>
                  </files-edit-field-static>
                </div>
              </a-col>
            </a-row>
          </a-col>
          <a-col>
            <a href="javascript:;" @click="handleSelected('unit', unit)">
              <template v-if="selected.indexOf(unit.id) > -1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  width="24"
                  height="24"
                  class="svg-danger"
                >
                  <path
                    d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
                  />
                </svg>
              </template>
              <template v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  width="24"
                  height="24"
                  class="svg-danger"
                >
                  <path
                    d="M384 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"
                  />
                </svg>
              </template>
            </a>
          </a-col>
        </a-row>
      </template>
    </template>
  </div>

  <div class="bg-gray p-5 my-5" v-if="filesStore.getAllPenality > 0">
    <a-row type="flex" justify="space-between" class="my-3">
      <a-col flex="auto" class="mx-3">
        <a-row type="flex" justify="space-between" align="middle">
          <a-col class="text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="24"
              height="24"
              class="d-flex"
            >
              <path
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
              />
            </svg>
          </a-col>
          <a-col flex="auto" class="text-info ms-2">
            <span>{{ t('global.label.cancellation_policies') }}</span>
          </a-col>
          <a-col class="text-warning">
            <span>{{ t('global.label.total_cost_price_with_penalty') }}:</span>
          </a-col>
        </a-row>

        <div class="p-4 border bg-white my-4">
          <a-form layout="vertical">
            <a-form-item label="¿Quién asume la penalidad?">
              <a-select
                size="large"
                :options="options_asumed_by"
                v-model:value="asumed_by"
                showSearch
                label-in-value
                :filter-option="false"
                :disabled="filesStore.isLoading || filesStore.isLoadingAsync"
              >
              </a-select>
            </a-form-item>
            <a-form-item label="Motivo">
              <a-textarea :rows="4" v-model:value="motive" />
            </a-form-item>
          </a-form>
        </div>
      </a-col>
      <a-col class="mx-3">
        <a-row type="flex" justify="start" align="middle">
          <a-col class="text-warning">
            <svg
              class="feather feather-check-circle"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
              <path d="M22 4 12 14.01l-3-3" />
            </svg>
          </a-col>
          <a-col class="text-warning ms-2">
            <h5 class="m-0">$ {{ filesStore.getAllPenality }}</h5>
          </a-col>
          <a-col class="text-warning ms-2">
            <small class="text-500">Debe pagar la penalidad si cambia o anula el servicio</small>
          </a-col>
        </a-row>
        <div class="p-4 border bg-white my-4">
          <p>Precio neto por tipo de habitación y tipo de pasajero</p>
          <a-row
            type="flex"
            justify="space-between"
            v-for="(_room, r) in filesStore.getFileItinerary.rooms"
            :key="r"
          >
            <a-col flex="auto" class="text-left">
              <small class="text-gray my-1" v-if="r == 0"> Precio neto </small>
              <p class="mb-1 text-warning">
                <span class="text-800">$ {{ _room.amount_cost / _room.total_adults }}</span>
              </p>
              <p class="mb-1 text-warning" v-if="_room.total_children > 0">
                <span class="text-800">$ {{ _room.amount_cost / _room.total_children }}</span>
              </p>
            </a-col>
            <a-col flex="auto" class="text-center">
              <small class="text-gray my-1" v-if="r == 0"> {{ t('global.label.unit') }} </small>
              <p class="mb-1">
                <span class="text-700">
                  <i class="bi bi-building-fill"></i>
                  {{ showTypeRoom(_room.total_adults / _room.total_rooms) }}
                </span>
              </p>
              <p class="mb-1" v-if="_room.total_children > 0">
                <span class="text-700">
                  <i class="bi bi-building-fill"></i>
                  {{ showTypeRoom(_room.total_children / _room.total_rooms) }}
                </span>
              </p>
            </a-col>
            <a-col class="text-right">
              <small class="text-gray my-1" v-if="r == 0"> {{ t('global.label.pax_type') }} </small>
              <p class="mb-1">
                <span class="text-700 me-1">ADL</span>
                <i class="bi bi-person-fill"></i>
              </p>
              <p class="mb-1" v-if="_room.total_children > 0">
                <span class="text-700 me-1">CHD</span>
                <i class="bi bi-person-arms-up"></i>
              </p>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
  import { onBeforeMount, ref, watch } from 'vue';
  import { useFilesStore } from '@store/files';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import { formatDate } from '@/utils/files.js';
  import FilesEditFieldStatic from '@/components/files/edit/FilesEditFieldStatic.vue';
  import { CalendarOutlined } from '@ant-design/icons-vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n({
    useScope: 'global',
  });

  const emit = defineEmits(['onChangeSelected']);
  const filesStore = useFilesStore();
  const selected = ref([]);

  defineProps({
    type: {
      type: String,
      default: () => '',
    },
    editable: {
      type: Boolean,
      default: () => true,
    },
  });

  const showTypeRoom = (_type) => {
    _type = parseFloat(Math.round(_type)).toFixed(0);

    let types = ['', 'Simple', 'Doble', 'Triple'];
    return types[_type];
  };

  onBeforeMount(async () => {
    await filesStore.getFileItinerary.rooms.forEach(async (room) => {
      handleSelected('room', room);
    });
  });

  const handleSelected = (type, data) => {
    if (type == 'room') {
      data.units.forEach((unit) => {
        let index = selected.value.indexOf(unit.id);

        if (index > -1) {
          selected.value.splice(index, 1);
        } else {
          selected.value.push(unit.id);
        }
      });
    }

    if (type == 'unit') {
      let index = selected.value.indexOf(data.id);

      if (index > -1) {
        selected.value.splice(index, 1);
      } else {
        selected.value.push(data.id);
      }
    }
  };

  watch(selected.value, () => {
    filesStore.calculatePenality(selected.value);
    emit('onChangeSelected', selected.value);
  });

  const validateSelected = (units) => {
    let response = 0;

    units.forEach((unit) => {
      if (selected.value.indexOf(unit.id) > -1) {
        response++;
      }
    });

    return response;
  };
</script>
