import axios from 'axios';
import Cookies from 'js-cookie';

window.TOKEN_KEY = import.meta.env.VITE_TOKEN_KEY_LIMATOUR;
window.url_back_a2 = import.meta.env.VITE_APP_BACKEND_URL;
const accessToken = Cookies.get(window.TOKEN_KEY);

const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${accessToken}`,
};

export default axios.create({ headers, timeout: 35000, baseURL: window.url_back_a2 });
