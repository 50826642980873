import { defineStore } from 'pinia';
import { handleError } from '@/utils/errorHandling';

import {
  activateFile,
  addItinerary,
  cancelFile,
  cloneBasicFile,
  createBasicFile,
  deleteItem,
  fetchAllProviders,
  fetchFiles,
  getCategoriesHotel,
  getDestiniesByClient,
  getFile,
  getFileCategories,
  getFileReasons,
  getFileStatusReasons,
  getFilterSearchHotels,
  getItinerary,
  getMasterServices,
  getReasonStatement,
  passengerDownload,
  passengerDownloadAmadeus,
  passengerDownloadPerurail,
  sendQuoteA2,
  updateAccommodations,
  updateAllPassengers,
  updateAmounts,
  updateFile,
  verifyQuoteA2,
  updateQuantityPassengers,
  getFilterSearchServices,
  getAllModifyPaxs,
} from '@service/files';

import {
  createFileAdapter,
  createFileItineraryReplaceAdapter,
  createFileItineraryRoomReplaceAdapter,
  createFileItineraryServiceReplaceAdapter,
  createFilesAdapter,
  createFileServiceAdapter,
} from '@store/files/adapters';

import { createFilePassengerAdapter } from './adapters/files';
import { notification } from 'ant-design-vue';
import { useMasterServiceStore } from '@/components/files/temporary/store/masterServiceStore';

const DEFAULT_PER_PAGE = 9;
const DEFAULT_PAGE = 1;

export const useFilesStore = defineStore({
  id: 'files',
  state: () => ({
    // loading
    loading: true,
    loading_itinerary: true,
    loading_async: false,
    // pagination
    total: 0,
    currentPage: DEFAULT_PAGE,
    defaultPerPage: DEFAULT_PER_PAGE,
    perPage: DEFAULT_PER_PAGE,
    // data
    files: [],
    filesCompleted: [],
    file: {},
    services: [],
    itineraries: [],
    passengers: [],
    reasons: [],
    fileCategories: [],
    fileReasonStatement: [],
    // filters
    filter: null,
    // sort
    filterBy: null,
    filterByType: null,
    filterNextDays: null,
    revisionStages: null,
    opeAssignStages: null,
    client_id: null,
    executiveCode: null,
    dateRange: null,
    new_itinerary: {},
    itinerary: {},
    itineraries_replace: [],
    itineraries_services_replace: [],
    categories: [],
    destinies: [],
    hotels: [],
    hotelsTop: [],
    filter_hotels: {},
    token_search_hotels: '',
    search_parameters_hotels: {},
    filter_services: {},
    token_search_services: '',
    search_parameters_services: {},
    services: [],
    status_reasons: [],
    all_penality: 0,
    penality_adl: 0,
    penality_chd: 0,
    penality_services: [],
    penality_hotels: [],
    provider: {},
    providers: [],
    flag_in_board: false,
    flag_send_board: false,
    loaded: false,
    serviceEdit: null,
    services_search: [],
    services_selected: [],
    search_parameters_services: {},
    quantity_adults: 0,
    quantity_children: 0,
    pages: 1,
    total_services: 0,
    search_passengers: [],
    modify_paxs: [],
  }),
  getters: {
    isLoaded: (state) => state.loaded,
    isLoading: (state) => state.loading,
    isLoadingItinerary: (state) => state.loading_itinerary,
    isLoadingAsync: (state) => state.loading_async,
    getLang: (state) => state.file.lang,
    getFiles: (state) => state.files,
    getFilesCompleted: (state) => state.filesCompleted,
    getFilesByClone: (state) => {
      return state.filesCompleted.map((file) => ({
        label: file.fileNumber + ' - ' + file.description,
        value: file.id,
      }));
    },
    getFile: (state) => state.file,
    getReasons: (state) => state.reasons,
    getCustomReasons: (state) => {
      return state.reasons.map((reason) => ({
        label: reason.name,
        value: reason.id,
      }));
    },
    getFileServices: (state) => state.services,
    getFileItineraries: (state) => state.itineraries,
    getFilePassengers: (state) => state.passengers,
    getTotal: (state) => state.total,
    getCurrentPage: (state) => state.currentPage,
    getDefaultPerPage: (state) => state.defaultPerPage,
    getPerPage: (state) => state.perPage,
    getFilter: (state) => state.filter,
    getFilterByType: (state) => state.filterByType,
    _hasFilterBy: (state) => state.filterBy === null || state.filterBy,
    getFileItinerary: (state) => state.new_itinerary,
    getFileItinerariesReplace: (state) => state.itineraries_replace,
    getFileItinerariesServicesReplace: (state) => state.itineraries_services_replace,
    getCategories: (state) => state.categories,
    getDestinies: (state) => state.destinies,
    getTokenSearchHotels: (state) => state.token_search_hotels,
    getTokenSearchServices: (state) => state.token_search_services,
    getSearchParametersHotels: (state) => state.search_parameters_hotels,
    getSearchParametersServices: (state) => state.search_parameters_services,
    getHotelsTop: (state) => state.hotelsTop,
    getHotels: (state) => state.hotels,
    getServices: (state) => state.services,
    getTotalServices: (state) => state.total_services,
    getPage: (state) => state.page,
    getPages: (state) => state.pages,
    getFilterServices: (state) => state.filter_services,
    getFilterHotels: (state) => state.filter_hotels,
    getStatusReasons: (state) => state.status_reasons,
    getAllPenality: (state) => state.all_penality,
    getPenalityADL: (state) => state.penality_adl,
    getPenalityCHD: (state) => state.penality_chd,
    getPenalityServices: (state) => state.penality_services,
    getPenalityHotels: (state) => state.penality_hotels,
    getProvider: (state) => state.provider,
    getFlagBoard: (state) => state.flag_in_board,
    getFlagSendBoard: (state) => state.flag_send_board,
    getFileCategories: (state) =>
      state.fileCategories.map((category) => ({
        value: category.id,
        label: category.name,
      })),
    getFileReasonStatement: (state) =>
      state.fileReasonStatement.map((reason) => ({
        value: reason.id,
        label: reason.name,
      })),
    getServiceEdit: (state) => state.serviceEdit,
    getSearchServices: (state) => state.services_search,
    getServicesSelected: (state) => state.services_selected,
    getDefaultAdults: (state) => state.quantity_adults,
    getDefaultChildren: (state) => state.quantity_children,
    getSearchPassengers: (state) => state.search_passengers,
    getModifyPaxs: (state) => state.modify_paxs,
  },
  actions: {
    clearFile() {
      this.loading = true;
      this.file = {};
    },
    changeLoaded(_loaded) {
      this.loaded = _loaded;
    },
    initedAsync() {
      this.loading_async = true;
    },
    inited() {
      this.loading = true;
      this.loading_itinerary = true;
    },
    finished() {
      this.loading = false;
      this.loading_itinerary = false;
      this.loading_async = false;
    },
    fetchAll({
      currentPage,
      perPage = DEFAULT_PER_PAGE,
      filter,
      filterBy,
      filterByType,
      executiveCode,
      clientId,
      dateRange,
    }) {
      this.loading = true;

      const filterNextDays = this.filterNextDays;
      const revisionStages = this.revisionStages;
      const opeAssignStages = this.opeAssignStages;

      const _dateRange =
        Array.isArray(dateRange) && dateRange.length === 2 ? dateRange.join(',') : '';

      return fetchFiles({
        currentPage: currentPage,
        perPage: perPage,
        filter: filter,
        filterBy: filterBy || this.filterBy,
        filterByType: filterByType || this.filterByType,
        executiveCode: executiveCode,
        clientId: clientId,
        dateRange: _dateRange,
        filterNextDays: filterNextDays,
        revisionStages: revisionStages,
        opeAssignStages: opeAssignStages,
        complete: 0,
      })
        .then(({ data }) => {
          this.total = data.pagination.total;
          this.currentPage = data.pagination.page;
          this.perPage = data.pagination.per_page;
          this.dateRange = dateRange;

          this.filter = filter;
          this.filterBy = filterBy;
          this.filterByType = filterByType;
          this.executiveCode = executiveCode;
          this.clientId = clientId;
          this.filterNextDays = filterNextDays;
          this.revisionStages = revisionStages;
          this.opeAssignStages = opeAssignStages;

          this.files = data.data.map((d) => createFilesAdapter(d));
          this.file = {};
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getById({ id, loading }) {
      if (loading == undefined || loading) {
        this.loading = true;
      }
      return getFile({ id })
        .then(({ data }) => {
          localStorage.setItem('client_id', data.data.client_id);

          this.file = createFileAdapter(data.data);
          const normalizedItineraries = data.data.itineraries.map((s) => {
            s.isLoading = false;
            s.flights_completed = false;
            if (s.entity === 'flight') {
              let f_registered = 0;
              s.flights.forEach((f) => {
                f.form_on = false;
                f.departure_time =
                  f.departure_time !== null && f.departure_time !== ''
                    ? f.departure_time.substring(0, 5)
                    : '';
                f.arrival_time =
                  f.arrival_time !== null && f.arrival_time !== ''
                    ? f.arrival_time.substring(0, 5)
                    : '';
                if (
                  f.pnr !== null &&
                  f.pnr !== '' &&
                  f.airline_code !== null &&
                  f.airline_code !== '' &&
                  f.airline_number !== null &&
                  f.airline_number !== '' &&
                  f.departure_time !== null &&
                  f.departure_time !== '' &&
                  f.accommodations.length > 0
                ) {
                  f_registered++;
                }
              });
              if (s.flights.length > 0 && f_registered >= s.flights.length) {
                s.flights_completed = true;
              }
            }
            return createFileServiceAdapter(s);
          });
          const sortedItineraries = normalizedItineraries;

          this.itineraries = sortedItineraries;
          this.passengers = data.data.passengers.map((s) => createFilePassengerAdapter(s));
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          // window.location.href = window.url_app + 'files/dashboard';
        });
    },
    getByNumber({ nrofile, loading }) {
      if (loading == undefined || loading) {
        this.loading = true;
      }

      return getFile({ nrofile })
        .then(({ data }) => {
          // this.file = data.data.data.map(d => createFileAdapter(d))
          localStorage.setItem('client_id', data.data.client_id);

          this.file = createFileAdapter(data.data);
          const normalizedItineraries = data.data.itineraries.map((s) => {
            s.isLoading = false;
            return createFileServiceAdapter(s);
          });
          const sortedItineraries = normalizedItineraries;

          this.itineraries = sortedItineraries;
          this.passengers = data.data.passengers.map((s) => createFilePassengerAdapter(s));
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          // window.location.href = window.url_app + 'files/dashboard';
        });
    },
    fetchStatusReasons() {
      this.loading_async = true;
      return getFileStatusReasons()
        .then(({ data }) => {
          if (data.success) {
            this.status_reasons = data.data;
          }
          this.loading_async = false;
        })
        .catch((error) => {
          this.loading_async = false;
          console.log(error);
        });
    },
    cancel(data) {
      this.loading = true;
      return cancelFile(data)
        .then(({ data }) => {
          console.log(data);
          localStorage.setItem('clear_file', 1);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    activate(data) {
      this.loading = true;
      return activateFile(data)
        .then(({ data }) => {
          console.log(data);
          localStorage.setItem('clear_file', 1);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    async getFileItineraryById({ id, object_id }) {
      this.loading_itinerary = true;
      if (this.itineraries.length == 0) {
        await this.getById({ id, loading: false });
      }

      let response = this.itineraries.filter((itinerary) => itinerary.id == object_id);
      this.new_itinerary = response[0];

      if (this.new_itinerary.entity == 'service') {
        this.new_itinerary.send_comunication = false;
        // Verificar que tiene envío de correos..
        this.new_itinerary.services.forEach((_service) => {
          _service.compositions.forEach((_composition) => {
            this.new_itinerary.send_comunication = _composition.supplier.send_communication;
          });
        });
      }

      setTimeout(() => {
        this.loading_itinerary = false;
      }, 100);
    },
    async getFileItineraryByMasterServiceId({ object_id }) {
      this.new_itinerary = this.itineraries.filter((itinerary) =>
        itinerary.services.filter((service) => service.id == object_id)
      );

      setTimeout(() => {
        this.loading_itinerary = false;
      }, 100);
    },
    async getFileItineraryByCompositionId({ object_id }) {
      this.new_itinerary = this.itineraries.filter((itinerary) =>
        itinerary.services.filter((service) =>
          service.compositions.filter((composition) => composition.id == object_id)
        )
      );

      setTimeout(() => {
        this.loading_itinerary = false;
      }, 100);
    },
    async getFileItineraryByRoomId({ object_id }) {
      this.loading_itinerary = true;

      this.itineraries.filter((itinerary) => {
        if (itinerary.entity == 'hotel') {
          itinerary.rooms.filter((room) => {
            if (room.id == object_id) {
              this.new_itinerary = itinerary;
              this.new_itinerary.rooms = [room];
            }
          });
        }
      });

      setTimeout(() => {
        this.loading_itinerary = false;
      }, 100);
    },
    getItineraryById({ itinerary_id }) {
      return getItinerary({ itinerary_id })
        .then(({ data }) => {
          this.itinerary = data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    search({ filter, perPage, clientId, executiveCode, dateRange, complete }) {
      this.fetchAll({
        currentPage: DEFAULT_PAGE,
        perPage: perPage,
        filter: filter,
        filterBy: this.filterBy,
        filterByType: this.filterByType,
        clientId: clientId,
        executiveCode: executiveCode,
        dateRange: dateRange,
        complete,
      });
    },
    fetchReasons() {
      return getFileReasons()
        .then(({ data }) => {
          this.reasons = data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchCategoriesHotel({ lang, client_id }) {
      this.loading_async = true;
      return getCategoriesHotel({ lang, client_id })
        .then(({ data }) => {
          this.categories = data.data;
          this.categories.unshift({
            class_id: '',
            class_name: 'Todos',
          });

          this.loading_async = false;
        })
        .catch((error) => {
          this.loading_async = false;
          console.log(error);
        });
    },
    fetchDestiniesByClient({ client_id }) {
      this.loading_async = true;
      return getDestiniesByClient({ client_id })
        .then(({ data }) => {
          this.destinies = data.map((destiny) => {
            let chunks = destiny.label.split(',');
            let label = '';

            chunks.forEach((chunk, c) => {
              chunk = chunk.trim();
              label += c > 1 ? ', ' : label;
              label += c > 0 ? chunk : label;
            });

            // let label = destiny.label.replace(', g', ',').replace(',g', ', ');
            return {
              code: destiny.code,
              label: label,
            };
          });
          this.loading_async = false;
        })
        .catch((error) => {
          this.loading_async = false;
          console.log(error);
        });
    },
    fetchHotels(params) {
      this.loading_async = true;
      this.hotelsTop = [];
      this.hotels = [];
      this.filter_hotels = params;

      return getFilterSearchHotels(params)
        .then(({ data }) => {
          this.token_search_hotels = data.data[0].city.token_search;
          this.search_parameters_hotels = data.data[0].city.search_parameters;

          data.data[0].city.hotels.forEach((_hotel) => {
            if (_hotel.popularity == 1 && this.hotelsTop.length < 7) {
              this.hotelsTop.push(_hotel);
            } else {
              this.hotels.push(_hotel);
            }
          });

          this.loading_async = false;
        })
        .catch((error) => {
          this.loading_async = false;
          console.log(error);
        });
    },
    changePageServices(page, perPage) {
      this.filter_services.page = page;
      this.filter_services.limit = perPage;
      this.fetchServices(this.filter_services);
    },
    fetchServices(params) {
      this.loading_async = true;
      this.services = [];
      this.filter_services = params;

      return getFilterSearchServices(params)
        .then(({ data }) => {
          if (data.success) {
            this.token_search_services = data.data.token_search;
            this.search_parameters_services = data.data.search_parameters;
            this.services = data.data.services;
            this.total_services = data.data.quantity_services;
            this.pages = data.data.last_page;
          } else {
            this.token_search_services = '';
            this.search_parameters_services = {};
            this.services = [];
            this.total_services = 0;
            this.pages = 0;
          }

          this.loading_async = false;
        })
        .catch((error) => {
          this.loading_async = false;
          console.log(error);
        });
    },
    changePage({ currentPage, perPage, filter, clientId, executiveCode, dateRange }) {
      const page = this._hasFilterBy ? currentPage : 1;
      this.currentPage = page;
      this.fetchAll({
        currentPage: page,
        perPage,
        filter,
        filterBy: this.filterBy,
        filterByType: this.filterByType,
        clientId,
        executiveCode,
        dateRange,
      });
    },
    sortBy({ filterBy, filterByType }) {
      this.filterBy = filterBy;
      this.filterByType = filterByType;
      this.fetchAll({
        currentPage: this.currentPage,
        filter: this.filter,
        filterBy,
        filterByType,
        clientId: this.clientId,
        executiveCode: this.executiveCode,
        dateRange: this.dateRange,
      });
    },
    update({ id, description, dateIn, passengers, lang }) {
      return updateFile({
        id,
        description,
        dateIn,
        passengers,
        lang,
      })
        .then(({ data }) => {
          console.log(data);
          localStorage.setItem('clear_file', 1);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    accommodations({ type, itinerary_id, object_id, passengers }) {
      this.loading_async = true;
      return updateAccommodations({
        type,
        object_id,
        passengers,
      })
        .then(() => {
          this.loading_async = false;

          if (type == 'room' || type == 'unit') {
            this.itineraries.forEach((itinerary) => {
              if (itinerary.id == itinerary_id) {
                itinerary.isLoading = true;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading_async = false;
        });
    },
    putUpdateAmounts(data) {
      // this.loading = true
      return updateAmounts(data, this.file.fileNumber)
        .then(({ data }) => {
          console.log('Response: ', data);
          // this.loading = false
        })
        .catch((error) => {
          console.log(error);
          // this.loading = false
        });
    },
    add_modify(data) {
      this.loading = true;
      return addItinerary(data)
        .then(({ data }) => {
          console.log(data);
          localStorage.setItem('clear_file', 1);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    delete(data) {
      this.loading = true;
      return deleteItem(data)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          localStorage.setItem('clear_file', 1);
          this.loading = false;
        });
    },
    putSearchPassengers(data) {
      this.search_passengers = data;
    },
    async putFileItinerariesServiceReplace(data) {
      let passengers = [];
      this.passengers.forEach((pax) => {
        if (data.passengers != undefined && data.passengers.indexOf(pax.id) > -1) {
          passengers.push(pax);
        }
      });

      let fileItineraryServiceReplace = createFileItineraryServiceReplaceAdapter(
        data.service,
        data.rate,
        data.adults,
        data.children,
        data.quantity,
        data.price,
        data.token_search,
        data.search_parameters_services,
        passengers
      );

      this.itineraries_services_replace.push(fileItineraryServiceReplace);
    },
    removeFileItineraryServiceReplace(index) {
      this.itineraries_services_replace.splice(index, 1);
    },
    async putFileItinerariesReplace(data) {
      let passengers = [];
      this.passengers.forEach((pax) => {
        if (data.passengers != undefined && data.passengers.indexOf(pax.id) > -1) {
          passengers.push(pax);
        }
      });

      let fileItineraryReplace = createFileItineraryReplaceAdapter(
        data.hotel,
        data.quantity,
        data.token_search,
        data.search_parameters,
        passengers
      );

      let flag_new_room = true;

      this.itineraries_replace.forEach((itinerary) => {
        console.log('Itinerario actual: ', itinerary);
        if (itinerary.id == fileItineraryReplace.id) {
          fileItineraryReplace.rooms.forEach((room, r) => {
            if (room.room_id == data.room.room_id) {
              flag_new_room = false;
              fileItineraryReplace.rooms[r].rates.push(data.rate);
            }
          });
        }
      });

      if (flag_new_room) {
        let fileItineraryRoomReplace = createFileItineraryRoomReplaceAdapter(data.room);
        fileItineraryReplace.rooms.push(fileItineraryRoomReplace);
        fileItineraryReplace.rooms[0].rates.push(data.rate);
      }

      this.itineraries_replace.push(fileItineraryReplace);
    },
    removeFileItineraryReplace(index) {
      this.itineraries_replace.splice(index, 1);
    },
    calculatePenalityService(type, object_id) {
      let penality = 0;

      if (type == 'itinerary') {
        this.itineraries.forEach((itinerary) => {
          if (itinerary.id == object_id) {
            itinerary.services.forEach((_service) => {});
          }
        });
      }

      return penality;
    },
    calculatePenalityRooms(rooms = [], object_id = 0) {
      let penality = 0;

      rooms.forEach((room) => {
        if (object_id == room.id || object_id == 0) {
          room.units.forEach((unit) => {
            penality += unit.penality;
          });
        }
      });

      return parseFloat(penality).toFixed(2);
    },
    calculatePenality(items = []) {
      this.loading_async = true;

      let flag_add;
      this.all_penality = 0;
      this.penality_adl = 0;
      this.penality_chd = 0;

      this.itineraries.forEach((itinerary) => {
        if (itinerary.entity == 'service') {
          if (itinerary.penality > 0 && (items.length == 0 || items.indexOf(itinerary.id) > -1)) {
            this.all_penality += itinerary.penality;
            this.penality_services.push(itinerary);
          }
        }

        if (itinerary.entity == 'hotel') {
          flag_add = false;
          itinerary.rooms.forEach((room) => {
            room.units.forEach((unit) => {
              if (itinerary.penality > 0 && (items.length == 0 || items.indexOf(unit.id) > -1)) {
                this.all_penality += unit.penality;
                flag_add = true;
              }
            });
          });

          if (flag_add) {
            this.penality_hotels.push(itinerary);
          }
        }
      });

      setTimeout(() => {
        this.loading_async = false;
      }, 100);
    },
    showRoomType(room_type) {
      if (room_type == 1) {
        return 'files.label.single';
      }

      if (room_type == 2) {
        return 'files.label.double';
      }

      if (room_type == 3) {
        return 'files.label.triple';
      }
    },
    showServiceIcon(object_code) {
      let digits = object_code.split('');

      if (digits[5] == 'I' || (digits[5] == 1 && digits[4] != 1) || digits[3] == 'P') {
        return 'bi bi-person-walking';
      }

      if (digits[4] == 1 || digits[5] == 1 || digits[2] == 1 || digits[3] == 1) {
        if (digits[4] == 1 && digits[5] == 1) {
          return 'bi bi-car-front-fill';
        } else {
          return 'bi bi-bus-front-fill';
        }
      }

      if (digits[3] == 'M') {
        return 'bi bi-ticket-detailed-fill';
      }

      if (digits[5] == 'P') {
        return 'bi bi-cup-hot-fill';
      }
    },
    fetchProviders(object_code) {
      this.loading_async = true;
      this.provider = {};

      return fetchAllProviders(object_code)
        .then(({ data }) => {
          if (!data.data.error) {
            this.provider = data.data[0];
          }
          this.loading_async = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading_async = false;
        });
    },
    verifyQuote() {
      this.loading_async = true;
      return verifyQuoteA2()
        .then(({ data }) => {
          if (data.success) {
            this.flag_in_board = data.data.flag_in_board.success;
          }
          this.loading_async = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading_async = false;
        });
    },
    sendQuote({ fileId, force = false }) {
      this.loading_async = true;
      return sendQuoteA2({ fileId, force })
        .then(({ data }) => {
          this.flag_send_board = data.success;
          this.loading_async = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading_async = false;
        });
    },
    downloadGenerate(data) {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'passengers.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadPassengerExcel({ fileId }) {
      this.loading_async = true;

      return passengerDownload({ fileId })
        .then(({ data }) => {
          this.downloadGenerate(data);
          this.loading_async = false;
        })
        .catch((error) => {
          this.loading_async = false;
          console.log(error);
        });
    },
    downloadPassengerExcelAmadeus({ fileId }) {
      this.loading_async = true;

      return passengerDownloadAmadeus({ fileId })
        .then(({ data }) => {
          this.downloadGenerate(data);
          this.loading_async = false;
        })
        .catch((error) => {
          this.loading_async = false;
          console.log(error);
        });
    },
    downloadPassengerExcelPerurail({ fileId }) {
      this.loading_async = true;

      return passengerDownloadPerurail({ fileId })
        .then(({ data }) => {
          this.downloadGenerate(data);
          this.loading_async = false;
        })
        .catch((error) => {
          this.loading_async = false;
          console.log(error);
        });
    },
    updatePassengers({ fileId, data }) {
      this.loading_async = true;
      return updateAllPassengers({ fileId, data })
        .then(() => {
          this.loading_async = false;
          notification.success({
            message: 'Éxito',
            description: 'Pasajeros actualizados correctamente',
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading_async = false;
        });
    },
    formatGuests(passengers) {
      let guests = [];

      passengers.forEach((pax) => {
        guests.push({
          given_name: pax.name,
          surname: pax.surnames,
          type: pax.type,
        });
      });

      return guests;
    },
    validateConfirmationCode(hotel) {
      let confirmation_code = '';
      let items = [];

      hotel.rooms.forEach((room) => {
        room.units.forEach((unit) => {
          if (items.indexOf(unit.confirmation_code) > -1) {
            confirmation_code = unit.confirmation_code;
          }
        });
      });

      return confirmation_code;
    },
    async storeBasicFile(data) {
      this.loading_async = true;
      try {
        const response = await createBasicFile(data);
        return response.data;
      } catch (error) {
        console.error('Error en storeBasicFile:', error);
        handleError(error);
        throw error;
      } finally {
        this.loading_async = false;
      }
    },
    async cloneBasicFile(fileId, data) {
      this.loading_async = true;
      try {
        const response = await cloneBasicFile(fileId, data);
        return response.data;
      } catch (error) {
        console.error('Error en cloneBasicFile:', error);
        handleError(error.data.error);
        throw error;
      } finally {
        this.loading_async = false;
      }
    },
    fetchFileCategories() {
      return getFileCategories()
        .then(({ data }) => {
          this.fileCategories = data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchFileReasonStatement() {
      return getReasonStatement()
        .then(({ data }) => {
          this.fileReasonStatement = data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchCompletedFiles({ currentPage, perPage = DEFAULT_PER_PAGE, filter = '' }) {
      this.loading = true;
      this.error = null;

      return fetchFiles({
        currentPage,
        perPage,
        filter,
        complete: 1,
      })
        .then((response) => {
          if (response && response.data && Array.isArray(response.data.data)) {
            this.filesCompleted = response.data.data.map((d) => createFilesAdapter(d));
          } else {
            throw new Error('Unexpected API response structure');
          }
        })
        .catch((error) => {
          console.error('Error fetching completed files:', error);
          this.error = error.message || 'An error occurred while fetching completed files';
          this.filesCompleted = [];
          throw error; // Re-throw the error to allow further handling
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchCompleted({ filter = '', perPage = 10 }) {
      return this.fetchCompletedFiles({
        currentPage: DEFAULT_PAGE,
        perPage,
        filter,
      }).catch((error) => {
        console.error('Error in searchCompleted:', error);
        this.error = error.message || 'An error occurred while searching completed files';
      });
    },
    searchMasterServices({ type_service = '', filter = '', page = 1, perPage = 10 }) {
      return getMasterServices({
        currentPage: page,
        perPage,
        type_service,
        filter,
      }).catch((error) => {
        console.error('Error in searchCompleted:', error);
        this.error = error.message || 'An error occurred while searching completed files';
      });
    },
    setServiceEdit(service) {
      this.serviceEdit = service;
      // Reiniciamos los estados de los servicios para limpiar cualquier marca previa
      if (this.serviceEdit && Array.isArray(this.serviceEdit.itinerary.services)) {
        this.serviceEdit.itinerary.services.forEach((service) => {
          service.isNew = false; // Indicar que estos servicios no son nuevos (originales)
          service.isDeleted = false; // No están marcados como eliminados
          service.isReplaced = false; // No están marcados como reemplazados
          service.replacedBy = null; // Ningún servicio los ha reemplazado
        });
      }
    },
    removeServiceFromEdit(serviceId) {
      // Verificar la cantidad de servicios activos (no eliminados)
      const activeServices = this.serviceEdit.itinerary.services.filter(
        (service) => !service.isDeleted
      );

      if (activeServices.length <= 1) {
        const message =
          'No se puede eliminar el último servicio. Debe haber al menos un servicio en la lista.';
        return { success: false, message };
      }

      // Buscar el servicio en `serviceEdit.itinerary.services`
      const service = this.serviceEdit.itinerary.services.find(
        (s) => s.master_service_id === serviceId
      );

      if (service) {
        if (service.isNew) {
          // Si el servicio es nuevo, lo eliminamos completamente de la lista
          this.serviceEdit.itinerary.services = this.serviceEdit.itinerary.services.filter(
            (s) => s.master_service_id !== serviceId
          );
        } else {
          // Si es un servicio existente, lo marcamos como eliminado
          service.isDeleted = true;
        }
        return { success: true, message: 'Servicio eliminado correctamente.' };
      } else {
        const message = 'El servicio no se encontró en la lista.';
        return { success: false, message };
      }
    },
    addSelectedMasterServicesToItinerary() {
      const masterServiceStore = useMasterServiceStore();
      // Verificar si `serviceEdit` y `serviceEdit.itinerary.services` existen
      if (!this.serviceEdit || !this.serviceEdit.itinerary) {
        console.error('El objeto serviceEdit o itinerary no está definido.');
        return;
      }

      if (!Array.isArray(this.serviceEdit.itinerary.services)) {
        this.serviceEdit.itinerary.services = [];
      }

      // Agregar los servicios seleccionados al `itinerary.services`
      masterServiceStore.getSelectedMasterServices.forEach((service) => {
        // Evita agregar servicios duplicados
        if (
          !this.serviceEdit.itinerary.services.some(
            (s) => s.master_service_id === service.master_service_id
          )
        ) {
          this.serviceEdit.itinerary.services.push(service);
        }
      });
    },
    setSearchServices: function (_services, _search_parameters) {
      this.services_search = _services;
      this.search_parameters_services = _search_parameters;
    },
    putServicesSelected: function (_service) {
      this.services_selected.push(_service);
    },
    getQuantityAdults: function (_passengers) {
      let quantity = 0;
      this.passengers.forEach((passenger) => {
        if (_passengers.indexOf(passenger.id) > -1 && passenger.type == 'ADL') {
          quantity += 1;
        }
      });
      this.quantity_adults = quantity;
      return quantity;
    },
    getQuantityChildren: function (_passengers) {
      let quantity = 0;
      this.passengers.forEach((passenger) => {
        if (_passengers.indexOf(passenger.id) > -1 && passenger.type == 'CHD') {
          quantity += 1;
        }
      });
      this.quantity_children = quantity;
      return quantity;
    },
    putQuantityPassengers: async function (object_id, data) {
      this.loading_async = true;
      return updateQuantityPassengers(object_id, data)
        .then(() => {
          this.loading_async = false;

          // Actualización en la visualización..
          this.itineraries.forEach((itinerary) => {
            if (itinerary.id == object_id) {
              itinerary.adults = data.total_adults;
              itinerary.children = data.total_children;
            }
          });

          notification.success({
            message: 'Éxito',
            description: 'Pasajeros actualizados correctamente',
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading_async = false;
        });
    },
    fetchModifyPaxs: function () {
      this.loading_async = true;
      this.modify_paxs = [];

      return getAllModifyPaxs(this.file.id)
        .then(({ data }) => {
          if (data.success) {
            this.modify_paxs = data.data;
          }

          this.loading_async = false;
        })
        .catch((error) => {
          this.loading_async = false;
          console.log(error);
        });
    },
  },
  persist: {
    pick: ['serviceEdit'],
    storage: localStorage,
  },
});
