<template>
  <template v-if="filesStore.isLoadingItinerary">
    <div class="files-edit">
      <a-skeleton rows="1" active />
    </div>
    <div class="files-edit files-edit__border">
      <a-skeleton rows="1" active />
    </div>
    <div class="files-edit files-edit__border">
      <a-skeleton rows="1" active />
    </div>
  </template>
  <div class="files-edit" v-else>
    <a-steps :current="step" size="large" class="p-5 mb-5">
      <a-step :title="showMessage(0)" :description="t('files.label.choose_hotel')" />
      <a-step :title="showMessage(1)" :description="t('files.label.communication_to_the_hotel')" />
      <a-step :title="showMessage(2)" :description="t('files.label.complete_reservation')" />
    </a-steps>

    <div v-if="step == 0">
      <div class="d-flex justify-content-between align-items-center mt-5">
        <div class="title">
          <i class="bi bi-grid text-danger"></i> {{ t('global.label.add_hotel') }}
        </div>
        <div class="actions">
          <a-button
            v-on:click="returnToProgram"
            class="text-600"
            danger
            :disabled="filesStore.isLoading"
            size="large"
          >
            {{ t('global.button.return_to_program') }}
          </a-button>
        </div>
      </div>

      <hotel-search
        @onNextStep="nextStep"
        :showHeader="false"
        @onReturnToProgram="returnToProgram"
      />
    </div>

    <div v-if="step == 1">
      <div class="d-flex justify-content-between align-items-center mt-5">
        <div class="title">
          <i class="bi bi-chat-left text-danger"></i> Comunicación al proveedor
        </div>
        <div class="actions">
          <a-button
            type="default"
            class="btn-default text-600"
            v-on:click="returnToProgram"
            default
            :disabled="filesStore.isLoading || filesStore.isLoadingAsync"
            size="large"
          >
            Volver al programa
          </a-button>
        </div>
      </div>

      <hotel-merge
        :from="filesStore.getFileItinerary"
        :to="filesStore.getFileItinerariesReplace"
        :selected="selected"
        type="new"
        @onLoadReservation="loadReservation"
        @onPrevStep="prevStep"
        @onNextStep="nextStep"
      />
    </div>

    <div v-if="step == 2">
      <div class="mt-5 pt-5">
        <div class="text-center">
          <h2 class="text-danger text-800">Reserva exitosa</h2>
          <div class="my-5">
            <i class="bi bi-check2-circle text-success" style="font-size: 5rem"></i>
          </div>
        </div>

        <div
          class="box-completed bg-light py-3 px-5"
          v-for="(item, index) in reservation.to"
          :key="index"
        >
          <a-row type="flex" align="middle" justify="space-between" class="mx-5">
            <a-col class="me-4">
              <span class="text-danger">
                <b>Detalle de la reserva</b>
              </span>
            </a-col>
            <a-col flex="auto" v-if="reservation.notes_to != '' && reservation.notes_to != ''">
              <span class="bg-white px-3 py-2 bordered w-100 ant-row-middle">
                <span class="d-flex mx-1">
                  <i class="bi bi-pencil"></i>
                </span>
                <span class="text-danger text-600">Notas para el proveedor:</span>
                <span class="mx-2">{{ reservation.notes_to }}</span>
              </span>
            </a-col>
          </a-row>
          <a-row type="flex" align="top" justify="space-between" class="my-3 mx-5">
            <a-col>
              <p>
                <b>Número de File:</b>
                <span>{{ filesStore.getFile.fileNumber }}</span>
              </p>
              <p>
                <b>Nombre del File:</b>
                <span>{{ filesStore.getFile.description }}</span>
              </p>
              <p>
                <b>Nacionalidad:</b> <span>{{ showLanguage() }}</span>
              </p>
              <p>
                <b>Fecha de Reserva:</b>
                <span>{{ filesStore.getFile.dateIn }}</span>
              </p>
              <p>
                <b>E-mail del ejecutivo:</b>
                <span class="text-lowercase"
                  >{{ filesStore.getFile.executiveCode }}@limatours.com.pe</span
                >
              </p>
            </a-col>
            <a-col>
              <p>
                <b class="text-danger">Fechas:</b>
              </p>
              <p>
                <b>Check-in: </b> {{ item.search_parameters.date_from }}
                <span class="text-danger">|</span> 15:00
              </p>
              <p>
                <b>Check-out: </b> {{ item.search_parameters.date_to }}
                <span class="text-danger">|</span> 15:00
              </p>
              <p><b>Cantidad de noches: 1</b></p>
            </a-col>
            <a-col>
              <p><b class="text-danger">Detalles de Habitación:</b></p>
              <p><b>Cantidad de Habitaciones: </b> {{ item.rooms.lenght }}</p>
              <p><b>Tipo de Habitación: </b> {{ item.rooms[0].room_type }}</p>
              <p>
                <b>Ocupantes: </b>
                <span class="text-uppercase">{{ item.rooms[0].occupation }}</span>
              </p>
            </a-col>
          </a-row>
          <a-row type="flex" align="middle" class="my-3 mx-5">
            <a-col :span="12">
              <p><b class="text-danger">Tarifas</b></p>
              <template v-for="(rate, r) in item.rooms[0].rates" :key="r">
                <p>
                  <b>Plan Tarifario:</b> {{ rate.name }} <span class="text-danger">|</span>
                  {{ item.code }}
                </p>
                <p><b>Total Tarifa:</b> USD {{ rate.total }}</p>
              </template>
            </a-col>
            <a-col :span="12">
              <a-row type="flex" align="middle" justify="start">
                <a-col>
                  <small><b>Status de la reserva: </b></small>
                </a-col>
                <a-col>
                  <template v-if="item.rooms[0].rates[0].onRequest == 0">
                    <a-tag class="d-flex ant-row-middle bg-success text-white py-2 mx-2">
                      <h6 class="bi bi-check2-circle mb-0"></h6>
                      <span class="text-600 mx-2">Confirmada</span>
                    </a-tag>
                  </template>
                  <template v-else>
                    <a-tag class="d-flex ant-row-middle bg-warning text-dark py-2 mx-2">
                      <h6 class="bi bi-exclamation-triangle mb-0"></h6>
                      <span class="text-600 mx-2">On Request</span>
                    </a-tag>
                  </template>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>

        <div class="box-buttons mt-5">
          <a-row type="flex" justify="center" align="middle">
            <a-col>
              <a-button
                type="primary"
                class="px-4"
                v-on:click="returnToProgram()"
                default
                :disabled="filesStore.isLoading"
                size="large"
              >
                {{ t('global.button.close') }}
              </a-button>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onBeforeMount, ref } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import {
    useFilesStore,
    useStatusesStore,
    useHaveInvoicesStore,
    useRevisionStagesStore,
  } from '@store/files';
  import { useLanguagesStore } from '@store/global';
  import HotelMerge from '@/components/files/reusables/HotelMerge.vue';
  import HotelSearch from '@/components/files/reusables/HotelSearch.vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n({
    useScope: 'global',
  });

  const router = useRouter();
  const route = useRoute();
  const filesStore = useFilesStore();

  const languagesStore = useLanguagesStore();
  const statusesStore = useStatusesStore();
  const haveInvoicesStore = useHaveInvoicesStore();
  const revisionStagesStore = useRevisionStagesStore();
  const selected = ref([]);
  const step = ref(0);
  const reservation = ref({});

  const showLanguage = () => {
    return languagesStore.find(filesStore.getFile.lang).label;
  };

  const showMessage = (_step) => {
    let message = t('global.label.finalized');

    if (step.value < _step) {
      message = t('global.label.on_hold');
    }

    if (step.value == _step) {
      message = t('global.label.in_progress');
    }

    return message;
  };

  const returnToProgram = async () => {
    router.push({ name: 'files-edit', params: route.params });
  };

  onBeforeMount(async () => {
    const { id } = route.params;

    if (typeof filesStore.getFile.id == 'undefined') {
      await statusesStore.fetchAll();
      await haveInvoicesStore.fetchAll();
      await revisionStagesStore.fetchAll();

      await filesStore.getById({ id });
    }

    filesStore.finished();
  });

  const prevStep = () => {
    step.value--;
  };

  const nextStep = () => {
    step.value++;
  };

  const loadReservation = async (data) => {
    reservation.value = data.reservation;

    data.params.forEach(async (item) => {
      await filesStore.add_modify(item);
    });

    nextStep();
  };
</script>
