import axios from 'axios';
// import Cookies from 'js-cookie'

window.APINODE = import.meta.env.VITE_APP_EXPRESS_WS_URL;

const headers = {
  'Content-Type': 'application/json',
  // 'Authorization': `Bearer ${accessToken}`
};

export default axios.create({ headers, timeout: 35000, baseURL: window.APINODE });
