<template>
  <a-steps :current="props.step">
    <a-step
      v-for="(step, index) in props.steps"
      :key="index"
      :title="step.title"
      :description="step.description"
    />
  </a-steps>
</template>

<script setup>
  import { defineProps } from 'vue';

  const props = defineProps({
    step: Number,
    steps: Array,
  });
</script>
