<template>
  <div>
    <a-modal
      class="file-modal"
      :open="isOpen"
      @cancel="handleCancel"
      :footer="null"
      :keyboard="!isSaving"
      :maskClosable="!isSaving"
      :focusTriggerAfterClose="false"
      width="1028px"
    >
      <template #title>
        <div class="service-tags">
          <div class="tag-modal tag-type-service">
            {{ serviceSelected.itinerary?.name }}
          </div>
        </div>
        <a-row>
          <a-col :span="24">
            <div class="title-service-master">
              <font-awesome-icon :icon="['fas', 'repeat']" size="lg" />
              Reemplazar servicio maestro
            </div>
          </a-col>
        </a-row>
      </template>
      <!-- Contenido del modal -->
      <div class="modal-content">
        <div v-if="masterServiceStore.getSelectedMasterServices.length > 0">
          <a-row :gutter="16" align="middle">
            <a-col :span="24">
              <a-divider style="height: 1px; background-color: #c4c4c4" />
            </a-col>
          </a-row>
          <a-row :gutter="16" align="middle">
            <a-col :span="24">
              <p class="fw-bold">Ha seleccionado</p>
            </a-col>
          </a-row>
          <a-row :gutter="16" align="middle">
            <a-col
              :span="7"
              v-for="(service, index) in masterServiceStore.getSelectedMasterServices"
              :key="index"
            >
              <div class="box-selected-service-master">
                <font-awesome-icon :icon="['fas', 'file-import']" size="lg" />
                <div class="mx-1 service-master-name">{{ service.name }}</div>
                <a-divider
                  style="height: 40px; width: 3px; background-color: #eb5757"
                  type="vertical"
                />
                <div class="mx-1 service-master-price">
                  $ {{ parseFloat(service.amount_cost).toFixed(2) }}
                </div>
                <div class="mx-1 service-master-delete" @click="handleDeleteServiceMaster(service)">
                  <font-awesome-icon :icon="['fas', 'x']" />
                </div>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="16" justify="middle" class="mt-4">
            <a-col :span="14" align="end">
              <span class="label-total-service">Total de servicios pagar:</span>
              <span class="label-total-price">$ {{ masterServiceStore.totalAmountCost }}</span>
            </a-col>
            <a-col :span="5">
              <a-button danger class="btn-cancel-add" @click="handleCancelServiceMaster">
                Cancelar
              </a-button>
            </a-col>
            <a-col :span="5">
              <a-button danger class="btn-add-service-master" @click="handleAddServiceMaster">
                Agregar servicio
              </a-button>
            </a-col>
          </a-row>
        </div>
        <a-row :gutter="16" align="middle">
          <a-col :span="24">
            <a-divider style="height: 1px; background-color: #c4c4c4" />
          </a-col>
        </a-row>
        <a-row :gutter="16" align="middle">
          <a-col :span="24">
            <div class="box-title"><span>Resultados de servicios</span></div>
          </a-col>
        </a-row>
        <a-row :gutter="16" align="middle" class="mt-5">
          <a-col :span="24" v-for="(service, index) in listMasterServices" :key="index">
            <div class="box-service-master">
              <div class="mx-2 icon-service">
                <font-awesome-icon :icon="['fas', 'bus']" size="lg" />
              </div>
              <div class="mx-2 service-master-name">{{ service?.name }}</div>
              <div class="mx-2 service-master-price">
                $ {{ parseFloat(service.amount_cost).toFixed(2) }}
              </div>
              <div class="mx-2 service-selection">
                <a-radio
                  v-model:checked="serviceMasterSelected[index]"
                  @change="toggleServiceSelection(service, index)"
                  size="lg"
                ></a-radio>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="16" align="middle" class="mt-5" v-if="listMasterServices.length > 0">
          <a-col :span="24" align="center">
            <BasePagination
              v-model:current="pagination.current"
              v-model:pageSize="pagination.pageSize"
              :total="pagination.total"
              :disabled="listMasterServices?.length === 0"
              :show-quick-jumper="true"
              :show-size-changer="true"
              @change="onChange"
            />
          </a-col>
        </a-row>
        <a-empty
          v-if="listMasterServices.length === 0"
          description="No se encontraron resultados"
        />
      </div>
    </a-modal>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, reactive, ref } from 'vue';
  import type { Service } from '@/components/files/temporary/interfaces/service.interface';
  import { useFilesStore } from '@store/files';
  import { useMasterServiceStore } from '@/components/files/temporary/store/masterServiceStore';
  import BasePagination from '@/components/files/reusables/BasePagination.vue';
  import type { PaginationInterface } from '@/modules/negotiations/interfaces/pagination.interface';

  const serviceSelected = ref<Record<number, boolean>>({});
  const serviceMasterSelected = ref([]);
  const listMasterServices = ref([]);
  const filesStore = useFilesStore();
  const masterServiceStore = useMasterServiceStore();
  const loading = ref(false);
  const pagination = ref<PaginationInterface>({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  defineProps({
    isOpen: {
      type: Boolean,
      required: true,
    },
  });

  const formState = reactive({
    type_service: null,
    filterSearch: '',
  });

  const resetFormState = () => {
    Object.assign(formState, {
      type_service: null,
      filterSearch: '',
    });
  };

  const emit = defineEmits(['update:isOpen', 'submit']);

  const handleSearchMaster = async (page: number = 1, pageSize: number = 10) => {
    try {
      loading.value = true;

      const currentPage = isNaN(page) ? 1 : page;
      const currentPageSize = isNaN(pageSize) ? 10 : pageSize;

      const response = await filesStore.searchMasterServices({
        type_service: formState.type_service,
        filter: formState.filterSearch,
        page: currentPage,
        pageSize: currentPageSize,
      });
      if (response && response.success && response.data.data.length > 0) {
        listMasterServices.value = response.data.data;
        pagination.value = {
          current: response.data.pagination.current_page,
          pageSize: response.data.pagination.per_page,
          total: response.data.pagination.total,
        };
      }
      loading.value = false;
    } catch (error) {
      loading.value = false;
      console.error('Error al crear el file:', error);
    }
  };

  const toggleServiceSelection = (service: Service, index: number) => {
    if (serviceMasterSelected.value[index]) {
      masterServiceStore.addService(service); // Agrega el servicio al store
    } else {
      masterServiceStore.removeService(service.master_service_id); // Elimina el servicio del store
    }
  };

  const handleCancel = () => {
    listMasterServices.value = [];
    serviceMasterSelected.value = [];
    loading.value = false;
    masterServiceStore.clearSelectedMasterServices();
    resetFormState();
    emit('update:isOpen', false);
  };

  const handleCancelServiceMaster = () => {
    masterServiceStore.clearSelectedMasterServices();
    serviceMasterSelected.value = [];
    resetFormState();
  };

  const handleDeleteServiceMaster = (service: Service) => {
    masterServiceStore.removeService(service.master_service_id);

    const serviceIndex = listMasterServices.value.findIndex(
      (item) => item.master_service_id === service.master_service_id
    );

    // Deseleccionar el checkbox en `serviceMasterSelected`
    if (serviceIndex !== -1) {
      serviceMasterSelected.value[serviceIndex] = false;
    }
  };

  const handleAddServiceMaster = () => {
    filesStore.addSelectedMasterServicesToItinerary();
    masterServiceStore.clearSelectedMasterServices();
    serviceMasterSelected.value = [];
    resetFormState();
    emit('update:isOpen', false);
  };

  const onChange = (page: number, perSize: number) => {
    handleSearchMaster(page, perSize);
  };

  onMounted(() => {
    serviceSelected.value = filesStore.getServiceEdit;
    handleSearchMaster();
  });
</script>

<style scoped lang="scss">
  .file-modal {
    .ant-form-item {
      margin-bottom: 0;
    }

    .fw-bold {
      font-weight: bold;
      font-size: 16px;
    }

    .option-clear-filters {
      font-size: 16px;
      font-weight: 400;
      color: #eb5757;
      cursor: pointer;
    }

    .title-service-master {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 24px;
      letter-spacing: -0.01em;
      color: #212529;
      text-align: left;
      display: flex;
      align-items: center;

      svg {
        color: #eb5757;
        margin-right: 10px;
      }
    }

    .service-tags {
      display: flex;
      gap: 35px;
      top: -20px;
      position: relative;
      justify-content: flex-end;
      margin-right: 20px;

      .tag-modal {
        color: #fff;
        padding: 10px 18px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      .tag-type-service {
        background-color: #ff4d4f;
      }
    }

    .modal-content {
      .search-collapse {
        ::v-deep(.ant-collapse-header) {
          background-color: #c4c4c4;
          border-radius: 6px 6px 0 0;

          .ant-collapse-header-text {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 18px;
            color: #fefefe;
          }
        }

        ::v-deep(.ant-collapse-content) {
          padding: 5px;
          background-color: #fefefe;
          border: 1px solid #e9e9e9;
        }

        .icon-arrow {
          color: #fefefe;
          font-size: 18px;
          font-weight: bold;
        }

        .title-collapse {
          font-weight: 700;
        }
      }
    }

    .btn-search-service-master {
      font-family: 'Montserrat', sans-serif;
      border-color: #eb5757;
      color: #eb5757;
      padding: 16px 48px;
      height: auto;
      width: 100%;
      font-size: 18px;

      svg {
        margin-right: 10px;
      }
    }

    .box-selected-service-master {
      display: flex;
      background-color: #fafafa;
      border-radius: 8px;
      padding: 8px;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;

      .service-master-name {
        font-size: 16px;
        font-weight: 500;
        color: #2e2e2e;
      }

      .service-master-price {
        font-size: 14px;
        font-weight: 600;
        color: #2e2e2e;
        width: 35%;
      }

      .service-master-delete {
        font-size: 14px;
        font-weight: 600;
        color: #c4c4c4;
        width: 15%;
        cursor: pointer;
        text-align: center;
        margin-left: 10px;

        svg {
          margin-right: 0;
          color: #c4c4c4;
        }
      }

      svg {
        margin-right: 10px;
      }
    }

    .btn-add-service-master {
      font-family: 'Montserrat', sans-serif;
      background-color: #eb5757;
      border-color: #eb5757;
      color: #ffffff;
      height: 54px;
      font-size: 16px;
      width: 100%;
    }

    .btn-cancel-add {
      font-family: 'Montserrat', sans-serif;
      background-color: #fafafa;
      border-color: #fafafa;
      color: #575757;
      font-weight: 500;
      font-size: 16px;
      height: 54px;
      width: 100%;
    }

    .label-total-service {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 18px;
      color: #2e2e2e;
    }

    .label-total-price {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 24px;
      color: #eb5757;
      margin-left: 10px;
    }

    .box-title {
      padding: 10px;
      background-color: #fafafa;

      span {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 18px;
        color: #3d3d3d;
        margin-left: 10px;
      }
    }

    .box-service-master {
      display: flex;
      background-color: #fafafa;
      border-radius: 8px;
      padding: 18px;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;

      .icon-service {
        width: 5%;
        text-align: center;
      }

      .service-master-name {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #2e2e2e;
        width: 75%;
      }

      .service-master-price {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: 700;
        width: 10%;
        color: #eb5757;
      }

      .service-selection {
        font-size: 14px;
        font-weight: 400;
        color: #2e2e2e;
        width: 10%;
      }
    }
  }
</style>
