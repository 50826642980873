import request from '../../utils/requestAmazon';

export function sendChunk(params) {
  return request({
    baseURL: window.url_s3,
    method: 'POST',
    url: 'chunk/upload',
    data: params,
  });
}

export function sendFiles(params) {
  return request({
    baseURL: window.url_s3,
    method: 'POST',
    url: 'upload',
    data: {
      files: params,
    },
  });
}
