<template>
  <div class="bg-pink-stick p-5 my-5">
    <div class="d-flex justify-content-between align-items-center">
      <div class="subtitle">
        <span>
          <i
            v-bind:class="filesStore.showServiceIcon(filesStore.getFileItinerary.object_code)"
            style="font-size: 1.2rem"
          ></i>
        </span>
        <span class="mx-2 text-uppercase">
          <b>{{ filesStore.getFileItinerary.name }}</b>
        </span>
        <span class="mx-2">
          <a-tag color="#c63838">
            {{ filesStore.getFileItinerary.category }}
          </a-tag>
        </span>
      </div>
      <div class="d-flex col text-dark-gray" style="gap: 5px">
        <CalendarOutlined />
        <b>{{ formatDate(filesStore.getFileItinerary.date_in, 'DD/MM/YYYY') }}</b>
      </div>
    </div>
    <a-row type="flex" align="middle" justify="start" class="mb-2 mt-3">
      <a-col flex="auto" class="d-flex" style="gap: 5px">
        <b>{{ t('global.label.passengers') }}:</b>
        <span><i class="bi bi-person-fill"></i> {{ filesStore.getFileItinerary.adults }}</span>
        <span><i class="bi bi-person-arms-up"></i> {{ filesStore.getFileItinerary.children }}</span>
      </a-col>
      <a-col flex="auto" class="d-flex" style="gap: 5px">
        <b class="text-danger">Más información del servicio</b>
      </a-col>
      <a-col flex="auto" class="d-flex" style="gap: 5px">
        <b>Costo:</b>
        <b class="text-danger">$ {{ filesStore.getFileItinerary.total_cost_amount }}</b>
      </a-col>
      <a-col flex="auto" class="d-flex" style="gap: 5px">
        <b>Penalidad por cancelación:</b>
        <b class="text-danger"
          >$
          {{ filesStore.calculatePenalityService('itinerary', filesStore.getFileItinerary.id) }}</b
        >
      </a-col>
    </a-row>
  </div>

  <div class="bg-gray p-5 my-5" v-if="filesStore.getAllPenality > 0">
    <a-row type="flex" justify="space-between" class="my-3">
      <a-col flex="auto" class="mx-3">
        <a-row type="flex" justify="space-between" align="middle">
          <a-col class="text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="24"
              height="24"
              class="d-flex"
            >
              <path
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
              />
            </svg>
          </a-col>
          <a-col flex="auto" class="text-info ms-2">
            <span>{{ t('global.label.cancellation_policies') }}</span>
          </a-col>
          <a-col class="text-warning">
            <span>{{ t('global.label.total_cost_price_with_penalty') }}:</span>
          </a-col>
        </a-row>

        <div class="p-4 border bg-white my-4">
          <a-form layout="vertical">
            <a-form-item label="¿Quién asume la penalidad?">
              <a-select
                size="large"
                :options="options_asumed_by"
                v-model:value="asumed_by"
                showSearch
                label-in-value
                :filter-option="false"
                :disabled="filesStore.isLoading || filesStore.isLoadingAsync"
              >
              </a-select>
            </a-form-item>
            <a-form-item label="Motivo">
              <a-textarea :rows="4" v-model:value="motive" />
            </a-form-item>
          </a-form>
        </div>
      </a-col>
      <a-col class="mx-3">
        <a-row type="flex" justify="start" align="middle">
          <a-col class="text-warning">
            <svg
              class="feather feather-check-circle"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
              <path d="M22 4 12 14.01l-3-3" />
            </svg>
          </a-col>
          <a-col class="text-warning ms-2">
            <h5 class="m-0">$ {{ filesStore.getAllPenality }}</h5>
          </a-col>
          <a-col class="text-warning ms-2">
            <small class="text-500">Debe pagar la penalidad si cambia o anula el servicio</small>
          </a-col>
        </a-row>
        <div class="p-4 border bg-white my-4">
          <p>Precio neto por tipo de pasajero</p>
          <a-row
            type="flex"
            justify="space-between"
            :key="s"
            v-for="(_service, s) in filesStore.getFileItinerary.services"
          >
            <a-col flex="auto" class="text-left">
              <small class="text-gray my-1" v-if="r == 0"> Costo neto </small>
              <p class="mb-1 text-warning">
                <span class="text-800">$ {{ _service.amount_cost / _service.total_adults }}</span>
              </p>
              <p class="mb-1 text-warning" v-if="_room.total_children > 0">
                <span class="text-800">$ {{ _service.amount_cost / _service.total_children }}</span>
              </p>
            </a-col>
            <a-col class="text-center">
              <small class="text-gray my-1" v-if="r == 0"> {{ t('global.label.pax_type') }} </small>
              <p class="mb-1">
                <span class="text-700 me-1">ADL</span>
                <i class="bi bi-person-fill"></i>
              </p>
              <p class="mb-1" v-if="_service.total_children > 0">
                <span class="text-700 me-1">CHD</span>
                <i class="bi bi-person-arms-up"></i>
              </p>
            </a-col>
            <a-col flex="auto" class="text-left">
              <small class="text-gray my-1" v-if="r == 0"> Cantidad </small>
              <p class="mb-1 text-warning">
                <span class="text-800">01</span>
              </p>
              <p class="mb-1 text-warning" v-if="_service.total_children > 0">
                <span class="text-800">01</span>
              </p>
            </a-col>
            <a-col flex="auto" class="text-left">
              <small class="text-gray my-1" v-if="r == 0"> Total </small>
              <p class="mb-1 text-warning">
                <span class="text-800">$ {{ _service.amount_sale }}</span>
              </p>
              <p class="mb-1 text-warning" v-if="_room.total_children > 0">
                <span class="text-800">$ {{ _service.amount_sale }}</span>
              </p>
            </a-col>
          </a-row>
          <a-row
            type="flex"
            justify="space-between"
            v-if="fileStore.getFileItinerary.services.length > 0"
          >
            <a-col flex="auto" class="text-left">
              <p class="mb-1 text-warning">
                <span class="text-800">$ {{ filesStore.getFileItinerary.total_amount }}</span>
              </p>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
  import { onBeforeMount } from 'vue';
  import { useFilesStore } from '@store/files';
  import { formatDate } from '@/utils/files.js';
  import { CalendarOutlined } from '@ant-design/icons-vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n({
    useScope: 'global',
  });

  const filesStore = useFilesStore();

  defineProps({
    type: {
      type: String,
      default: () => '',
    },
    editable: {
      type: Boolean,
      default: () => true,
    },
  });

  onBeforeMount(async () => {
    let selected = [];
    selected.push(filesStore.getFileItinerary.id);
    filesStore.calculatePenality(selected);

    console.log('Itinerario: ', filesStore.getFileItinerary);
    console.log('Penality: ', filesStore.getAllPenality);
  });
</script>
