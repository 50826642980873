<template>
  <add-service-component
    :defaultOpen="true"
    defaultCategory="tours"
    :showHeaderIcon="false"
    :showCheckbox="true"
    :showHotels="false"
    :isFile="true"
  />

  <div class="services-items" v-if="filesStore.getFileItinerariesServicesReplace.length > 0">
    <a-row type="flex" align="middle" justify="space-between" class="pb-4">
      <a-col :span="4">
        <p class="text-selected">Has seleccionado</p>
      </a-col>
      <a-col :span="20">
        <a-row :gutter="[16, 8]">
          <template v-for="(_service, s) in filesStore.getFileItinerariesServicesReplace">
            <a-col :span="12">
              <a-row
                type="flex"
                align="middle"
                justify="space-between"
                class="item-selected item-selected-default d-flex justify-content-between align-items-center rounded"
              >
                <a-col>
                  <font-awesome-icon class="item-selected-icon" icon="fa-solid fa-file-import" />
                </a-col>
                <a-col>
                  <p class="item-selected-title mb-0" style="max-width: 330px">
                    {{ _service.name }}
                  </p>
                </a-col>
                <a-col>
                  <span class="item-selected-divider"> | </span>
                </a-col>
                <a-col>
                  <p class="item-selected-span">{{ _service.rate.name }}</p>
                </a-col>
                <a-col>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="item-selected-price">
                      <font-awesome-icon class="pr-1" icon="fa-solid fa-dollar-sign" />
                      <b>{{ _service.price * _service.quantity }}</b>
                    </div>
                    <a-tag class="ant-tag-ok" v-if="_service.rate.status == 1">OK</a-tag>
                  </div>
                </a-col>
                <a-col>
                  <span class="ml-2 text-dark-gray cursor-pointer" @click="removeItem(s)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x-lg d-flex"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
                      />
                    </svg>
                  </span>
                </a-col>
              </a-row>
            </a-col>
          </template>
        </a-row>
      </a-col>
    </a-row>
    <a-alert
      message="Agregar servicios"
      description="Al presionar agregar se añadirán al file los servicios seleccionados que no tienen comunicación e irá al paso 2 para servicios con comunicación."
      type="info"
      show-icon
    />
    <a-row type="flex" align="middle" justify="end" class="price-total" style="gap: 7px">
      <a-col>
        <span class="price-total-info">Total de servicios pagar:</span>
      </a-col>
      <a-col>
        <b class="title text-danger">
          <font-awesome-icon class="pr-1" icon="fa-solid fa-dollar-sign" />
          {{ totalSum }}
        </b>
      </a-col>
      <a-col>
        <a-button
          type="default"
          class="mx-2 px-4 text-600"
          default
          size="large"
          v-on:click="cancel()"
          :loading="filesStore.isLoadingAsync"
        >
          {{ t('global.button.cancel') }}
        </a-button>
      </a-col>
      <a-col>
        <a-button
          type="primary"
          class="mx-2 px-4 text-600"
          default
          v-on:click="nextStep()"
          :loading="filesStore.isLoadingAsync"
          size="large"
        >
          Agregar servicios
        </a-button>
      </a-col>
    </a-row>
    <hr class="my-5" />
  </div>

  <template v-if="filesStore.isLoadingAsync || filesStore.isLoadingAsync">
    <div class="my-3">
      <a-skeleton rows="1" active />
    </div>
    <div class="my-3">
      <a-skeleton rows="1" active />
    </div>
  </template>

  <template v-if="!filesStore.isLoadingAsync">
    <template v-if="filesStore.getServices.length > 0">
      <div class="box-hotels p-4 mt-3" v-for="(_service, s) in filesStore.getServices" :key="s">
        <a-row
          type="flex"
          justify="space-between"
          align="top"
          v-for="(_rate, r) in _service.rate.rate_plans"
        >
          <a-col>
            <div class="h6 text-700 p-0" style="max-width: 600px">
              <i class="bi bi-building-fill" style="font-size: 1.3rem"></i>
              {{ _service.name }}
            </div>
            <a-tag class="text-white bg-danger mt-2" :bordered="false">
              {{ _service.category.category }}
            </a-tag>
            <span class="cursor-pointer" @click="toggleViewServiceDetails(_service, s)">
              Más información del servicio
            </span>
          </a-col>
          <a-col> </a-col>
          <a-col>
            <a-row type="flex" justify="space-between" align="middle">
              <a-col>
                <i class="bi bi-info-circle me-2" style="font-size: 1.3rem"></i>
              </a-col>
              <a-col>
                <span class="me-3"> <b>Tarifa:</b> {{ _service.rate.name }} </span>
              </a-col>
              <a-col>
                <b class="mx-2 text-danger"
                  >$.
                  {{
                    parseFloat(
                      parseFloat(_rate.price_adult * filesStore.getDefaultAdults) +
                        parseFloat(_rate.price_child * filesStore.getDefaultChildren)
                    ).toFixed(2)
                  }}</b
                >
                <span class="mx-1">
                  <a-tag class="bg-success text-white" v-if="_service.rate.on_request == 0"
                    >OK</a-tag
                  >
                  <a-tag class="bg-danger text-white" v-else>RQ</a-tag>
                </span>
              </a-col>
              <a-col>
                <span class="cursor-pointer" v-on:click="toggleRate(_rate, _service)">
                  <template v-if="items.indexOf(_rate.id) > -1">
                    <i class="bi bi-check-square-fill text-danger" style="font-size: 1.5rem"></i>
                  </template>
                  <template v-else>
                    <i
                      class="bi bi-square text-danger text-dark-light"
                      style="font-size: 1.5rem"
                    ></i>
                  </template>
                </span>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </template>

    <BasePagination
      v-if="filesStore.getPages > 1"
      v-model:current="currentPage"
      v-model:pageSize="perPage"
      :total="filesStore.getTotalServices"
      :show-quick-jumper="true"
      :show-size-changer="true"
      @change="onChange"
    />

    <div class="my-3" v-if="step > 0">
      <a-row type="flex" justify="end" align="middle">
        <a-col>
          <a-button
            type="default"
            class="btn-default mx-2 px-4 text-600"
            v-on:click="returnToProgram()"
            default
            :disabled="filesStore.isLoading || filesStore.isLoadingAsync"
            size="large"
          >
            {{ t('global.button.cancel') }}
          </a-button>
          <a-button
            v-if="items.length > 0"
            type="primary"
            class="mx-2 px-4 text-600"
            v-on:click="nextStep()"
            default
            :disabled="filesStore.isLoading || filesStore.isLoadingAsync"
            size="large"
          >
            {{ t('global.button.continue') }}
          </a-button>
        </a-col>
      </a-row>
    </div>
  </template>
</template>

<script setup>
  import { onBeforeMount, ref, computed } from 'vue';
  import { useFilesStore } from '@store/files';
  import AddServiceComponent from '@/quotes/components/AddServiceComponent.vue';
  // import { useQuote } from '@/quotes/composables/useQuote';
  import useQuoteDestinations from '@/quotes/composables/useQuoteDestinations';
  import useQuoteLanguages from '@/quotes/composables/useQuoteLanguages';
  import useQuoteDocTypes from '@/quotes/composables/useQuoteDocTypes';
  import useCountries from '@/quotes/composables/useCountries';
  import { useQuoteServices } from '@/quotes/composables/useQuoteServices';
  import BasePagination from '@/components/files/reusables/BasePagination.vue';

  import { useI18n } from 'vue-i18n';
  const { t } = useI18n({
    useScope: 'global',
  });

  const emit = defineEmits(['onReturnToPogram', 'onNextStep']);
  const filesStore = useFilesStore();

  const returnToProgram = () => {
    emit('onReturnToProgram');
  };

  defineProps({
    showHeader: {
      type: Boolean,
      default: () => true,
    },
  });

  const currentPage = ref(1);
  const perPage = ref(10);

  const onChange = () => {
    filesStore.changePageServices(currentPage.value, perPage.value);
  };
  // Lógica relacionada con la obtención de datos para los componentes
  // const { getQuote } = useQuote();
  const {
    getServicesCategories,
    getServicesExperiences,
    getServicesDurations,
    getServiceTypeMeals,
    getServicesDestinations,
  } = useQuoteServices();
  const { getDestinations } = useQuoteDestinations();
  const { getLanguages } = useQuoteLanguages();
  const { getDoctypes } = useQuoteDocTypes();
  const { getCountries } = useCountries();

  // Función para obtener los componentes necesarios
  const loadingResources = ref(false);
  const items = ref([]);

  const getComponents = async () => {
    loadingResources.value = true;
    const resources = [];

    resources.push(getServicesCategories());
    resources.push(getServicesExperiences());
    resources.push(getServicesDurations());
    resources.push(getServiceTypeMeals());
    resources.push(getServicesDestinations());
    resources.push(getDestinations());
    resources.push(getLanguages());
    resources.push(getDoctypes());
    // resources.push(getQuote());
    resources.push(getCountries());

    await Promise.all(resources).then(() => (loadingResources.value = false));
  };

  onBeforeMount(async () => {
    if (filesStore.getFileItinerariesReplace.length > 0) {
      filesStore.getFileItinerariesReplace.forEach((item, index) => {
        filesStore.removeFileItineraryReplace(index);
      });
    }

    await getComponents();
  });

  const toggleRate = (rate, service) => {
    let index = items.value.indexOf(rate.id);

    if (index > -1) {
      items.value.splice(index, 1);
      filesStore.removeFileItineraryServiceReplace(index);
    } else {
      let params = {
        service: service,
        rate: rate,
        quantity: 1,
        adults: filesStore.getDefaultAdults,
        children: filesStore.getDefaultChildren,
        token_search: filesStore.getTokenSearchServices,
        search_parameters_services: filesStore.getSearchParametersServices,
        price:
          parseFloat(rate.price_adult * filesStore.getDefaultAdults) +
          parseFloat(rate.price_child * filesStore.getDefaultChildren),
        passengers: filesStore.getSearchPassengers,
      };

      items.value.push(rate.id);
      filesStore.putFileItinerariesServiceReplace(params);
    }
  };

  const totalSum = computed(() => {
    return filesStore.getFileItinerariesServicesReplace.reduce((total, service) => {
      return total + service.price * service.quantity;
    }, 0);
  });

  const removeItem = (_index) => {
    console.log('Eliminando: ', _index);
    items.value.splice(_index, 1);
    filesStore.removeFileItineraryServiceReplace(_index);
  };

  const cancel = () => {
    emit('onReturnToProgram');
  };

  const nextStep = () => {
    emit('onNextStep');
  };
</script>
