import { defineStore } from 'pinia';
import type {
  Hotel,
  QuoteResponse,
  QuoteService,
  GroupedServices,
  QuotePricePassengersResponse,
  QuotePriceRangesResponse,
} from '@/quotes/interfaces';

interface QuoteState {
  view: string;
  page: string;
  quote: QuoteResponse;
  quoteServiceTypeId: number;
  quoteLanguageId: number | null;
  selectedCategory: number;
  quoteResponse: QuoteResponse;
  serviceSelected: QuoteService | GroupedServices;
  hotelSelected?: Hotel;
  hotelRoomSelected?: Hotel;
  quotePricePassenger: QuotePricePassengersResponse | null;
  quotePriceRanger: QuotePriceRangesResponse | null;
  downloadItinerary: null;
  currentReportQuote: null;
  downloadSkeletonUse: null;
  quoteRowExtentions: number;
  itemsExtensiones: null;
  selectedHotelDetails: number | null;
  reportsList: null;
  reportsDestinity: null;
  marketList: null;
  sellersList: null;
  openItemService: boolean;
  alertCategory: number | null;
}

export const useQuoteStore = defineStore({
  id: 'quoteStore',
  state: () =>
    ({
      view: 'table',
      page: 'details',
      quote: {} as QuoteResponse,
      quoteServiceTypeId: 0,
      quoteLanguageId: null,
      quoteResponse: {} as QuoteResponse,
      serviceSelected: {} as QuoteService,
      selectedCategory: 0,
      selectedHotelDetails: null,
      quotePricePassenger: {} as QuotePricePassengersResponse,
      quotePriceRanger: {} as QuotePriceRangesResponse,
      downloadItinerary: {},
      currentReportQuote: {},
      downloadSkeletonUse: {},
      quoteRowExtentions: {},
      itemsExtensiones: {},
      reportsList: {},
      reportsDestinity: {},
      marketList: {},
      sellersList: {},
      openItemService: false,
      alertCategory: null,
    }) as QuoteState,
  actions: {
    setView(view: string) {
      this.view = view;
    },
    setQuote(quote: QuoteResponse) {
      this.quote = quote;
    },
    setQuoteResponse(quoteResponse: QuoteResponse) {
      this.quoteResponse = quoteResponse;
    },
    setSelectedCategory(c: number) {
      this.selectedCategory = c;
    },
    setHotelSelected(hotel: Hotel) {
      this.hotelSelected = hotel;
    },
    setServiceSelected(serviceSelected: QuoteService | GroupedServices) {
      this.serviceSelected = serviceSelected;
    },
    unsetServiceSelected() {
      this.serviceSelected = {} as QuoteService;
    },
    unsetQuote() {
      this.quote = {} as QuoteResponse;
    },
  },
});
