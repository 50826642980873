<template>
  <div class="box-search">
    <div class="box-search-header" v-if="showHeader">
      <div class="title mt-5">
        <i class="bi bi-grid text-danger"></i>
        {{ t('files.label.search_hotel') }}
      </div>
    </div>

    <div class="box-search-filters">
      <div class="mt-3 mb-3 mx-2">
        <a-row type="flex" class="d-flex">
          <a-col flex="auto">
            <a-radio-group v-model:value="filter.country">
              <a-radio value="PE">Perú</a-radio>
            </a-radio-group>
          </a-col>
          <a-col flex="auto">
            <a-row type="flex" align="middle" justify="end">
              <a-col class="d-flex ant-row-middle">
                <i class="bi bi-building-fill" style="font-size: 1.2rem"></i>
              </a-col>
              <a-col v-bind:class="['d-flex ant-row-middle ms-2', !flag_SGL ? 'text-dark' : '']">
                <span @click="flag_SGL = !flag_SGL" class="d-flex cursor-pointer">
                  <template v-if="flag_SGL">
                    <i class="bi bi-check-square-fill text-danger" style="font-size: 1.5rem"></i>
                  </template>
                  <template v-else>
                    <i class="bi bi-square text-dark-light" style="font-size: 1.5rem"></i>
                  </template>
                </span>
                <span @click="flag_SGL = !flag_SGL" class="mx-1 cursor-pointer">{{
                  t('files.label.single')
                }}</span>
                <a-input
                  type="number"
                  v-model:value="quantity_sgl"
                  style="width: 80px"
                  v-if="flag_SGL"
                  min="0"
                />
              </a-col>
              <a-col
                v-bind:class="['d-flex ant-row-middle ms-2', !flag_DBL ? 'svg-dark-light' : '']"
              >
                <span @click="flag_DBL = !flag_DBL" class="d-flex cursor-pointer">
                  <template v-if="flag_DBL">
                    <i class="bi bi-check-square-fill text-danger" style="font-size: 1.5rem"></i>
                  </template>
                  <template v-else>
                    <i class="bi bi-square text-dark-light" style="font-size: 1.5rem"></i>
                  </template>
                </span>
                <span @click="flag_DBL = !flag_DBL" class="mx-1 cursor-pointer">{{
                  t('files.label.double')
                }}</span>
                <a-input
                  type="number"
                  v-model:value="quantity_dbl"
                  style="width: 80px"
                  v-if="flag_DBL"
                  min="0"
                />
              </a-col>
              <a-col
                v-bind:class="['d-flex ant-row-middle ms-2', !flag_TPL ? 'svg-dark-light' : '']"
              >
                <span @click="flag_TPL = !flag_TPL" class="d-flex cursor-pointer">
                  <template v-if="flag_TPL">
                    <i class="bi bi-check-square-fill text-danger" style="font-size: 1.5rem"></i>
                  </template>
                  <template v-else>
                    <i class="bi bi-square text-dark-light" style="font-size: 1.5rem"></i>
                  </template>
                </span>
                <span @click="flag_TPL = !flag_TPL" class="mx-1 cursor-pointer">{{
                  t('files.label.triple')
                }}</span>
                <a-input
                  type="number"
                  v-model:value="quantity_tpl"
                  style="width: 80px"
                  v-if="flag_TPL"
                  min="0"
                />
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </div>

    <div class="box-search-content">
      <a-card title="Buscar hotel">
        <div>
          <a-form class="ant-row-space-between" layout="inline">
            <a-form-item>
              <label for="destiny" class="d-block mb-1"
                >{{ t('files.label.destiny') }} <b class="text-danger">*</b></label
              >
              <a-select
                style="width: 200px"
                :allowClear="true"
                id="destiny"
                v-model:value="filter.destiny"
                :showSearch="true"
                size="large"
                optionFilterProp="label"
                :fieldNames="{ label: 'label', value: 'code' }"
                :options="filesStore.getDestinies"
              >
              </a-select>
            </a-form-item>
            <a-form-item>
              <label for="category" class="d-block mb-1">{{ t('files.label.category') }}</label>
              <a-select
                style="width: 150px"
                :allowClear="false"
                id="category"
                size="large"
                v-model:value="filter.category"
                :fieldNames="{ label: 'class_name', value: 'class_id' }"
                :options="filesStore.getCategories"
              >
              </a-select>
            </a-form-item>
            <a-form-item>
              <label for="date_range" class="d-block mb-1"
                >{{ t('files.label.check_in') }} - {{ t('files.label.check_out') }}
                <b class="text-danger">*</b></label
              >
              <a-range-picker
                :disabledDate="disabledDate"
                size="large"
                v-model:value="filter.date_range"
                id="date_range"
              />
            </a-form-item>
            <a-form-item>
              <label for="passengers" class="d-block mb-1"
                >{{ t('global.label.passengers') }} <b class="text-danger">*</b></label
              >
              <a-select
                mode="tags"
                id="passengers"
                size="large"
                v-model:value="filter.passengers"
                :fieldNames="{ label: 'label', value: 'id' }"
                style="width: 180px"
                max-tag-count="responsive"
                :options="filesStore.getFilePassengers"
              >
              </a-select>
            </a-form-item>
            <a-form-item :span="4">
              <label for="price_range" class="d-block mb-1">{{
                t('files.label.price_range')
              }}</label>
              <div class="d-flex ant-row-middle">
                <b class="text-danger pt-2 me-2">
                  <i class="bi bi-currency-dollar"></i>
                </b>
                <a-slider
                  range
                  id="price_range"
                  v-model:value="filter.price"
                  :marks="marks"
                  :min="0"
                  :max="950"
                  style="width: 200px"
                />
                <b class="text-danger pt-2 ms-2">
                  <i class="bi bi-currency-dollar"></i>
                </b>
              </div>
            </a-form-item>
          </a-form>

          <a-form class="ant-row-space-between ant-row-bottom mt-2" layout="inline">
            <a-form-item style="width: 790px">
              <label for="search" class="d-block mb-1">{{
                t('files.label.filter_by_hotel_name')
              }}</label>
              <a-input
                v-model:value="filter.search"
                placeholder="..."
                autocomplete="off"
                class="w-100"
                size="large"
                id="search"
              ></a-input>
            </a-form-item>
            <a-form-item>
              <a-button
                type="dashed"
                danger
                @click="onReset"
                size="large"
                class="d-flex ant-row-middletext-600"
                :disabled="filesStore.isLoadingAsync"
              >
                <i class="bi bi-magic"></i>
                <span class="mx-2">{{ t('global.button.clear_filters') }}</span>
              </a-button>
            </a-form-item>
            <a-form-item>
              <a-button
                danger
                @click="onSubmit"
                size="large"
                class="d-flex ant-row-middletext-600"
                :loading="filesStore.isLoadingAsync"
              >
                <i class="bi bi-search" v-if="!filesStore.isLoadingAsync"></i>
                <span v-bind:class="[!filesStore.isLoadingAsync ? 'ms-2' : '']">{{
                  t('global.button.search')
                }}</span>
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </a-card>
    </div>
  </div>

  <div class="box-selected my-4 mx-2" v-if="filesStore.getFileItinerariesReplace.length > 0">
    <a-row type="flex" justify="space-between" align="middle">
      <a-col>
        <b>{{ t('global.label.selected') }}</b>
      </a-col>
      <a-col :span="16">
        <a-row>
          <template v-for="(item, i) in filesStore.getFileItinerariesReplace">
            <template template v-for="(room, r) in item.rooms" :key="r">
              <a-row
                align="middle"
                justify="space-between"
                v-bind:class="[
                  'item-selected ant-row-middle',
                  item.top ? 'item-selected-success' : 'item-selected-default',
                ]"
              >
                <a-col>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="1rem"
                      height="1rem"
                      class="svg-danger mx-1 d-flex"
                    >
                      <path
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM228 104c0-11-9-20-20-20s-20 9-20 20v14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1l0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4V312c0 11 9 20 20 20s20-9 20-20V298.2c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7V104z"
                      />
                    </svg>
                  </span>
                </a-col>
                <a-col>
                  <span class="mx-1">{{ item.quantity }}</span>
                </a-col>
                <a-col>
                  <span class="text-danger text-700">|</span>
                </a-col>
                <a-col>
                  <span class="mx-1">{{ showTextOccupation(room.occupation) }}</span>
                </a-col>
                <a-col><span class="text-danger text-700">|</span></a-col>
                <a-col
                  ><span class="mx-1">{{ item.name }}</span></a-col
                >
                <a-col>
                  <span class="mx-1 text-dark-gray cursor-pointer" v-on:click="removeItem(r + i)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x-lg d-flex"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
                      />
                    </svg>
                  </span>
                </a-col>
              </a-row>
            </template>
          </template>
        </a-row>
      </a-col>
      <a-col>
        <a-button
          v-on:click="nextStep()"
          type="primary"
          class="px-4 text-600"
          default
          :disabled="filesStore.isLoading || filesStore.isLoadingAsync || flag_locked"
          size="large"
        >
          {{ t('global.button.continue') }}
        </a-button>
      </a-col>
    </a-row>
  </div>

  <template v-if="filesStore.isLoadingAsync || filesStore.isLoadingAsync">
    <div class="my-3">
      <a-skeleton rows="1" active />
    </div>
    <div class="my-3">
      <a-skeleton rows="1" active />
    </div>
  </template>

  <template v-if="!filesStore.isLoadingAsync && flag_search">
    <div class="box-top-hotels p-4 mt-3" v-if="filesStore.getHotelsTop.length > 0">
      <a-row type="flex" justify="space-between" align="middle">
        <a-col>
          <div class="subtitle p-0 d-flex ant-row-middle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="16"
              height="16"
              class="svg-danger mx-1 d-flex"
            >
              <path
                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM228 104c0-11-9-20-20-20s-20 9-20 20v14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1l0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4V312c0 11 9 20 20 20s20-9 20-20V298.2c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7V104z"
              />
            </svg>
            <span>{{ t('files.label.option_hotels_top') }}</span>
          </div>
        </a-col>
        <a-col>
          <span class="cursor-pointer" @click="flagTopHotels = !flagTopHotels">
            <font-awesome-icon
              v-if="flagTopHotels"
              icon="fa-solid fa-chevron-up"
              size="lg"
              class="svg-danger"
            />
            <font-awesome-icon
              v-if="!flagTopHotels"
              icon="fa-solid fa-chevron-down"
              size="lg"
              class="svg-danger"
            />
          </span>
        </a-col>
      </a-row>
      <div v-if="flagTopHotels">
        <template v-for="(_hotel, h) in filesStore.getHotelsTop" :key="h">
          <div
            class="hotel-top-item bg-light px-4 py-3 mt-3"
            v-if="_hotel.rooms.length > 0 && _hotel.price > 0"
          >
            <template v-for="(_room, r) in _hotel.rooms" :key="'item-' + h + '-' + r">
              <template v-if="_room.best_price > 0">
                <a-row type="flex" justify="space-between" align="middle" v-if="r == 0">
                  <a-col>
                    <h6 class="text-700 me-2">
                      <i class="bi bi-building-fill" style="font-size: 1.3rem"></i>
                      {{ _hotel.name }}
                    </h6>
                    <a-tag
                      class="text-white"
                      v-if="_hotel.flag_show || typeof _hotel.flag_show == 'undefined'"
                      :color="_hotel.color_class"
                    >
                      {{ _hotel.class }}
                    </a-tag>
                  </a-col>
                </a-row>
                <a-row :span="24" type="flex" justify="space-between" align="middle">
                  <template v-for="(_rate, r) in _room.rates" :key="'rate' + h + '-' + r">
                    <a-col v-if="_rate.total > 0" class="py-2">
                      <a-row type="flex" justify="space-between" align="top">
                        <a-col>
                          <span class="text-dark-gray me-2">
                            {{ moment(_rate.rate[0].amount_days[0].date).format('DD/MM/YYYY') }}
                            <template v-if="_rate.rate[0].amount_days.length > 1">
                              |
                              {{
                                moment(
                                  _rate.rate[0].amount_days[_rate.rate[0].amount_days.length - 1]
                                    .date
                                ).format('DD/MM/YYYY')
                              }}
                            </template>
                          </span>
                        </a-col>
                        <a-col>
                          <span class="text-danger text-700 me-2">
                            {{ _rate.rate[0].amount_days.length }} {{ t('global.label.nights') }}
                          </span>
                        </a-col>
                        <a-col>
                          <span class="me-3">
                            <b>{{ t('global.label.room') }}:</b> {{ _room.name }}
                          </span>
                        </a-col>
                        <a-col>
                          <div class="d-flex">
                            <span class="mx-1">
                              <i
                                class="d-flex bi bi-check2-circle text-success"
                                style="font-size: 1.3rem"
                                v-if="_rate.onRequest == 0"
                              ></i>
                              <i
                                class="d-flex bi bi-exclamation-triangle text-warning"
                                style="font-size: 1.3rem"
                                v-else
                              ></i>
                            </span>
                            <span class="mx-1">
                              <i class="d-flex bi bi-info-circle" style="font-size: 1.3rem"></i>
                            </span>
                            <b class="mx-2">{{ _rate.name_commercial }}</b>
                            <b class="text-danger">$ {{ _rate.total }}</b>
                          </div>
                        </a-col>
                      </a-row>
                    </a-col>
                    <a-col class="d-flex" style="gap: 5px">
                      <template v-if="_rate.total > 0">
                        <a-input
                          type="number"
                          size="small"
                          v-model:value="_rate.quantity_room"
                          style="width: 80px; border: 1px solid #ddd"
                          v-if="items.indexOf(_rate.rateId) > -1"
                          min="1"
                          v-on:change="toggleRate(_rate, _room, _hotel, true, false)"
                        />
                        <span
                          class="cursor-pointer"
                          v-on:click="toggleRate(_rate, _room, _hotel, true)"
                        >
                          <template v-if="items.indexOf(_rate.rateId) > -1">
                            <i
                              class="bi bi-check-square-fill text-danger"
                              style="font-size: 1.5rem"
                            ></i>
                          </template>
                          <template v-else>
                            <i
                              class="bi bi-square text-danger text-dark-light"
                              style="font-size: 1.5rem"
                            ></i>
                          </template>
                        </span>
                      </template>
                    </a-col>
                  </template>
                </a-row>
              </template>
            </template>
          </div>
        </template>
      </div>
    </div>

    <template v-if="filesStore.getHotels.length > 0">
      <div class="box-hotels p-4 mt-3" v-for="(_hotel, h) in filesStore.getHotels" :key="h">
        <a-row type="flex" justify="space-between" align="top">
          <a-col>
            <div class="h6 text-700 p-0">
              <i class="bi bi-building-fill" style="font-size: 1.3rem"></i>
              {{ _hotel.name }}
            </div>
            <a-tag
              class="text-white mt-2"
              v-if="_hotel.flag_show || (h == 0 && typeof _hotel.flag_show == 'undefined')"
              :color="_hotel.color_class"
            >
              {{ _hotel.class }}
            </a-tag>
          </a-col>
          <a-col>
            <span class="cursor-pointer" @click="toggleViewHotelDetails(_hotel, h)">
              <font-awesome-icon
                v-if="_hotel.flag_show || (h == 0 && typeof _hotel.flag_show == 'undefined')"
                icon="fa-solid fa-chevron-up"
                size="lg"
                class="svg-danger"
              />
              <font-awesome-icon
                v-else
                icon="fa-solid fa-chevron-down"
                size="lg"
                class="svg-danger"
              />
            </span>
          </a-col>
        </a-row>

        <template v-if="_hotel.flag_show || (h == 0 && typeof _hotel.flag_show == 'undefined')">
          <template v-if="_hotel.rooms.length > 0 && _hotel.price > 0">
            <div class="px-2 mt-2" v-for="(_room, r) in _hotel.rooms" :key="r">
              <a-row
                type="flex"
                justify="space-between"
                align="top"
                class="hotel-item pt-2"
                v-if="_room.best_price > 0"
              >
                <a-col :span="12">
                  <div class="d-flex me-2" style="gap: 5px">
                    <b>{{ t('files.label.name') }}:</b>
                    <span class="text-dark-gray"> {{ _room.name }}</span>
                  </div>
                  <div class="d-flex me-2" style="gap: 5px">
                    <b>{{ t('files.label.description') }}:</b>
                    <span class="text-dark-gray"> {{ _room.room_type }}</span>
                  </div>
                </a-col>
                <a-col :span="12">
                  <template v-for="(_rate, r) in _room.rates" :key="r">
                    <a-row align="top" justify="space-between">
                      <a-col>
                        <a-row align="top" justify="space-between">
                          <a-col class="mx-2 pt-2 d-flex ant-row-middle">
                            <span class="mx-1">
                              <i
                                class="d-flex bi bi-check2-circle text-success"
                                style="font-size: 1.3rem"
                                v-if="_rate.onRequest == 0"
                              ></i>
                              <i
                                class="d-flex bi bi-exclamation-triangle text-warning"
                                style="font-size: 1.3rem"
                                v-else
                              ></i>
                            </span>
                            <span class="mx-1">
                              <i class="d-flex bi bi-info-circle" style="font-size: 1.3rem"></i>
                            </span>
                          </a-col>
                          <a-col>
                            <div class="d-block">
                              <b>{{ _rate.name_commercial }}</b>
                            </div>
                            <div class="d-block my-1">
                              <small class="text-600 text-dark-gray me-2">
                                {{ moment(_rate.rate[0].amount_days[0].date).format('DD/MM/YYYY') }}
                                <template v-if="_rate.rate[0].amount_days.length > 1">
                                  -
                                  {{
                                    moment(
                                      _rate.rate[0].amount_days[
                                        _rate.rate[0].amount_days.length - 1
                                      ].date
                                    ).format('DD/MM/YYYY')
                                  }}
                                </template>
                              </small>
                            </div>
                            <div class="d-block">
                              <b class="text-danger">$ {{ _rate.total }}</b>
                            </div>
                          </a-col>
                        </a-row>
                      </a-col>
                      <a-col class="d-flex pt-2" style="gap: 5px">
                        <template v-if="_rate.total > 0">
                          <a-input
                            type="number"
                            size="small"
                            v-model:value="_rate.quantity_room"
                            style="width: 80px; border: 1px solid #ddd"
                            v-if="items.indexOf(_rate.rateId) > -1"
                            min="0"
                            v-on:change="toggleRate(_rate, _room, _hotel, false, false)"
                          />
                          <span
                            class="cursor-pointer"
                            v-on:click="toggleRate(_rate, _room, _hotel, false)"
                          >
                            <template v-if="items.indexOf(_rate.rateId) > -1">
                              <i
                                class="bi bi-check-square-fill text-danger"
                                style="font-size: 1.5rem"
                              ></i>
                            </template>
                            <template v-else>
                              <i
                                class="bi bi-square text-danger text-dark-light"
                                style="font-size: 1.5rem"
                              ></i>
                            </template>
                          </span>
                        </template>
                      </a-col>
                    </a-row>
                  </template>
                </a-col>
              </a-row>
            </div>
          </template>
        </template>
      </div>
    </template>

    <div class="my-3">
      <a-row type="flex" justify="end" align="middle">
        <a-col>
          <a-button
            type="default"
            class="mx-2 px-4 text-600"
            v-on:click="returnToProgram()"
            default
            :disabled="filesStore.isLoading || filesStore.isLoadingAsync"
            size="large"
          >
            {{ t('global.button.cancel') }}
          </a-button>
          <a-button
            v-if="items.length > 0"
            type="primary"
            class="mx-2 px-4 text-600"
            v-on:click="nextStep()"
            default
            :disabled="filesStore.isLoading || filesStore.isLoadingAsync || flag_locked"
            size="large"
          >
            {{ t('global.button.continue') }}
          </a-button>
        </a-col>
      </a-row>
    </div>
  </template>
</template>

<script setup>
  import { onBeforeMount, ref } from 'vue';
  import { useFilesStore } from '@store/files';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import moment from 'moment';
  import { notification } from 'ant-design-vue';
  import { useI18n } from 'vue-i18n';
  import dayjs from 'dayjs';

  const { t } = useI18n({
    useScope: 'global',
  });

  const emit = defineEmits(['onReturnToPogram', 'onNextStep']);

  const filesStore = useFilesStore();

  const filter = ref({
    country: 'PE',
  });

  const flagTopHotels = ref(true);
  const flag_locked = ref(false);
  const marks = ref({
    0: '0',
    150: '150',
    500: '500',
    950: '950',
  });

  const flag_SGL = ref(false);
  const flag_DBL = ref(false);
  const flag_TPL = ref(false);
  const items = ref([]);

  const quantity_sgl = ref(0);
  const quantity_dbl = ref(0);
  const quantity_tpl = ref(0);
  const flag_search = ref(false);

  const returnToProgram = () => {
    emit('onReturnToProgram');
  };

  defineProps({
    showHeader: {
      type: Boolean,
      default: () => true,
    },
  });

  onBeforeMount(async () => {
    let client_id = localStorage.getItem('client_id');
    let lang = localStorage.getItem('lang');

    await filesStore.fetchDestiniesByClient({ client_id });
    await filesStore.fetchCategoriesHotel({ lang, client_id });

    if (filesStore.getFileItinerariesReplace.length > 0) {
      filesStore.getFileItinerariesReplace.forEach((item, index) => {
        filesStore.removeFileItineraryReplace(index);
      });
    }
  });

  const validateQuantityRooms = () => {
    let max = 0;
    let quantity = 0;

    if (flag_SGL.value) {
      max += parseInt(quantity_sgl.value, 10);
    }

    if (flag_DBL.value) {
      max += parseInt(quantity_dbl.value, 10);
    }

    if (flag_TPL.value) {
      max += parseInt(quantity_tpl.value, 10);
    }

    filesStore.getFileItinerariesReplace.forEach((item) => {
      quantity += parseInt(item.rooms[0].occupation * item.quantity, 10);
    });

    if (quantity > 0 && max != quantity) {
      notification['error']({
        message: `Selección de Habitaciones`,
        description:
          'No coinciden las habitaciones seleccionadas con las habitaciones buscadas. Por favor, intente nuevamente.',
        duration: 5,
      });

      flag_locked.value = true;

      return false;
    } else {
      flag_locked.value = false;
    }
  };

  const toggleRate = (rate, room, hotel, top, update) => {
    if (typeof rate.quantity_room === 'undefined') {
      rate.quantity_room = 1; // Seteando el valor del rate..
    }

    if (update == false) {
      let index = items.value.indexOf(rate.rateId);

      if (index > -1) {
        items.value.splice(index, 1);
        filesStore.removeFileItineraryReplace(index);
      }
    }

    let index = items.value.indexOf(rate.rateId);

    if (index > -1) {
      items.value.splice(index, 1);
      filesStore.removeFileItineraryReplace(index);
    } else {
      let quantity = rate.quantity_room > 0 ? rate.quantity_room : 1;

      let params = {
        token_search: filesStore.getTokenSearchHotels,
        search_parameters: filesStore.getSearchParametersHotels,
        hotel: hotel,
        room: room,
        rate: rate,
        hotel_name: hotel.name,
        occupation: room.occupation,
        top: top,
        quantity: quantity,
        passengers: filter.value.passengers,
      };

      items.value.push(rate.rateId);
      filesStore.putFileItinerariesReplace(params);
    }

    validateQuantityRooms();
  };

  const toggleViewHotelDetails = (hotel, h) => {
    if (typeof hotel.flag_show == 'undefined') {
      hotel.flag_show = h == 0 ? false : true;
    } else {
      hotel.flag_show = !hotel.flag_show;
    }
  };

  const removeItem = (_index) => {
    items.value.splice(_index, 1);
    filesStore.removeFileItineraryReplace(_index);
  };

  const showTextOccupation = (occupation) => {
    let response = '';

    if (occupation >= 1 || occupation <= 3) {
      response = 'SGL';

      if (occupation > 1) {
        response = 'DBL';
      }

      if (occupation > 2) {
        response = 'TPL';
      }
    }

    return response;
  };

  const nextStep = () => {
    emit('onNextStep');
  };

  const disabledDate = (current) => {
    return current && current < dayjs(filesStore.getFile.dateIn, 'YYYY-MM-DD');
  };

  const onSubmit = async () => {
    let client_id = localStorage.getItem('client_id');
    let lang = localStorage.getItem('lang');
    let quantity_temp = 0;

    if (client_id == '' || client_id == null) {
      client_id = 15766;
    }

    if (
      (!flag_SGL.value || quantity_sgl.value == 0) &&
      (!flag_DBL.value || quantity_dbl.value == 0) &&
      (flag_TPL.value || quantity_tpl.value == 0)
    ) {
      notification['error']({
        message: `Búsqueda de Hoteles`,
        description: t('global.message.room_quantity_selected_error'),
        duration: 5,
      });

      return false;
    }

    if (typeof filter.value.destiny == 'undefined') {
      notification['error']({
        message: `Búsqueda de Hoteles`,
        description: t('global.message.destiny_error'),
        duration: 5,
      });

      return false;
    }

    if (typeof filter.value.date_range == 'undefined') {
      notification['error']({
        message: `Búsqueda de Hoteles`,
        description: t('global.message.date_range_error'),
        duration: 5,
      });

      return false;
    }

    if (typeof filter.value.passengers == 'undefined' || filter.value.passengers.length == 0) {
      notification['error']({
        message: `Búsqueda de Hoteles`,
        description: t('global.message.passengers_selected_error'),
        duration: 5,
      });

      return false;
    }

    let rooms = [];

    if (quantity_sgl.value > 0) {
      rooms.push({
        adults: 1,
        child: 0,
        ages_child: [],
        room: quantity_sgl.value,
      });

      quantity_temp += parseFloat(quantity_sgl.value);
    }

    if (quantity_dbl.value > 0) {
      rooms.push({
        adults: 2,
        child: 0,
        ages_child: [],
        room: quantity_dbl.value,
      });

      quantity_temp += parseFloat(quantity_dbl.value * 2);
    }

    if (quantity_tpl.value > 0) {
      rooms.push({
        adults: 3,
        child: 0,
        ages_child: [],
        room: quantity_tpl.value,
      });

      quantity_temp += parseFloat(quantity_tpl.value * 3);
    }

    /*
    let quantity_paxs = 0;

    filter.value.passengers.forEach((pax) => {
      if (pax.type == 'ADL') {
        quantity_paxs++;
      }
    });
    */

    if (filter.value.passengers.length != quantity_temp) {
      notification['error']({
        message: `Búsqueda de Hoteles`,
        description: t('global.message.accommodation_selected_error'),
        duration: 5,
      });

      return false;
    }

    let params = {
      client_id: client_id,
      date_from: moment(filter.value.date_range[0].toDate()).format('YYYY-MM-DD'),
      date_to: moment(filter.value.date_range[1].toDate()).format('YYYY-MM-DD'),
      quantity_persons_rooms: rooms,
      destiny: {
        code: filter.value.destiny,
        label: '',
      },
      hotels_search_code: filter.value.search,
      lang: lang,
      quantity_rooms: 1,
      typeclass_id: filter.value.category,
      zero_rates: true,
    };

    await filesStore.fetchHotels(params);
    flag_search.value = true;
  };

  const onReset = () => {
    filter.value = {
      country: 'PE',
      price: [0, 0],
    };
    quantity_sgl.value = 0;
    quantity_dbl.value = 0;
    quantity_tpl.value = 0;
    items.value = [];
  };
</script>
