import axios from 'axios';
import Cookies from 'js-cookie';

window.TOKEN_KEY = import.meta.env.VITE_TOKEN_KEY_COGNITO_LIMATOUR;
window.API = import.meta.env.VITE_APP_BACKEND + '/api/v1/commercial/files-ms';

const accessTokenCognito = Cookies.get(window.TOKEN_KEY);

const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${accessTokenCognito}`,
};

const instance = axios.create({
  headers,
  timeout: 35000,
  baseURL: window.API,
});

// Agregar un interceptor para manejar respuestas y errores
instance.interceptors.response.use(
  (response) => {
    // Manejar respuestas exitosas (status 2xx)
    if (response.status === 200 || response.status === 201) {
      return {
        success: true,
        data: response.data,
        status: response.status,
        code: response.status,
      };
    }
    // Si la respuesta no es 200 o 201, pero aún está en el rango 2xx
    return {
      success: true,
      data: response.data,
      status: response.status,
      code: response.status,
      message: 'Operación exitosa pero con un código de estado inusual',
    };
  },
  (error) => {
    if (error.response) {
      // El servidor respondió con un código de estado fuera del rango 2xx
      const errorResponse = {
        success: false,
        status: error.response.status,
        code: error.response.status,
        data: error.response.data,
      };

      switch (error.response.status) {
        case 422:
          errorResponse.type = 'validation';
          errorResponse.errors = error.response.data.error || error.response.data;
          break;
        case 401:
          errorResponse.type = 'authentication';
          errorResponse.message = 'No autorizado. Por favor, inicie sesión nuevamente.';
          // Aquí podrías redirigir al login o refrescar el token
          break;
        case 403:
          errorResponse.type = 'authorization';
          errorResponse.message = 'No tiene permisos para realizar esta acción.';
          break;
        case 404:
          errorResponse.type = 'not_found';
          errorResponse.message = 'El recurso solicitado no fue encontrado.';
          break;
        default:
          if (error.response.status >= 500) {
            errorResponse.type = 'server_error';
            errorResponse.message = 'Error interno del servidor. Por favor, intente más tarde.';
          } else {
            errorResponse.type = 'client_error';
            errorResponse.message = 'Ocurrió un error en la solicitud.';
          }
      }

      return Promise.reject(errorResponse);
    } else if (error.request) {
      // La petición fue hecha pero no se recibió respuesta
      return Promise.reject({
        success: false,
        type: 'network_error',
        message: 'No se recibió respuesta del servidor. Por favor, verifique su conexión.',
        code: 0,
      });
    } else {
      // Algo sucedió en la configuración de la petición que provocó un error
      return Promise.reject({
        success: false,
        type: 'request_config_error',
        message: 'Error al configurar la petición: ' + error.message,
        code: 0,
      });
    }
  }
);

export default instance;
